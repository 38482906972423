import React from "react";
import { Link } from "react-router-dom";

const HomeServiceCard = (props) => {
  return (
    <div
      className={
        "relative lg:w-[20%] w-[30%] transition-transform hover:scale-105 duration-500 hover:text-amber-500"
      }
    >
      <Link
        to={`${process.env.REACT_APP_API_BASE_URL}/${props.link}`}
        aria-label="Read More"
      >
        <div
          className={
            "relative overflow-hidden rounded-2xl shadow-xl  cursor-pointer flex flex-col   "
          }
        >
          <div className={"p-4 xl:text-6xl lg:text=5xl text-black   text-4xl"}>
            {props.icon}
          </div>
          <div
            className={
              "p-4 text-black min-h-[170px] flex flex-col justify-around"
            }
          >
            <h2
              className={
                "md:text-2xl lg:text-3xl text-xl mb-3 w-[50px]  align-middle font-bold rounded-full"
              }
            >
              {props.number}
            </h2>
            <h2 className={"md:text-xl lg:text-2xl text-sm"}>{props.title}</h2>

            <p className={"font-light text-sm"}></p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default HomeServiceCard;
