import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/varients";
import Card from "../common/Card";
import { GalleryData } from "../../data/galleryData/galleryData";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { IoMdArrowDown } from "react-icons/io";

const GalleryTiles = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Usługi Remontowe i Wykończeniowe Wieliczka | Galeria Zdjęć`;
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Usługi Remontowe i Wykończeniowe Wieliczka | Galeria Zdjęć
        </title>
      </Helmet>
      <div className="section lg:mt-0 mt-24">
        <div className="container mx-auto">
          <motion.h2
            variants={fadeIn("up")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.6 }}
            className="h2 max-w-[370px] lg:mb-20 flex lg:justify-start justify-center"
          >
            Zobacz nasze projekty:
          </motion.h2>
        </div>
        <motion.div
          variants={fadeIn("up")}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: "some" }}
          className="mb-8 lg:mb-20  flex flex-row flex-wrap justify-center pt-0 xl:gap-8 gap-4 opacity-100"
        >
          {GalleryData.map((gallery, index) => (
            <Card
              key={index}
              imgSrc={gallery.imgSrc}
              imgAlt={gallery.imgAlt}
              title={gallery.title}
              link={gallery.link}
              description={gallery.description}
            ></Card>
          ))}
        </motion.div>{" "}
        <Link
          to={`${process.env.REACT_APP_API_BASE_URL}/oferta`}
          className={"w-full flex justify-center"}
        >
          <button className="btn btn-lg btn-dark" aria-label="Zobacz więcej">
            Zobacz więcej
            <div className="text-xl">
              <IoMdArrowDown />
            </div>
          </button>
        </Link>
      </div>
    </>
  );
};

export default GalleryTiles;
