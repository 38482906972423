import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./components/Home";
import About from "./components/Home/About";
import VideoSection from "./components/Home/VideoSection";
import LandingPage from "./components/Home/LandingPage";
import Skills from "./components/Home/Skills";
import Testimonial from "./components/Home/Testimonial";
import Reviews from "./components/Home/Reviews";
import ServicesTiles from "./components/Services/ServicesTiles";
import GalleryItem from "./components/gallery/GalleryItem";
import FAQs from "./components/faq/FAQs";
import ContactForm from "./components/contact/Contact";
import Blog from "./components/blog/Blog";
import Service from "./components/Services/Service";
import Services from "./components/Services/Services";
import BlogList from "./components/blog/BlogList";
import BlogItem from "./components/blog/BlogItem";
import Gallery from "./components/gallery/Gallery";
import GalleryTiles from "./components/gallery/GalleryTiles";
import Error404Page from "./components/404";

const router = createBrowserRouter(
  [
    {
      path: "/",
      errorElement: <Error404Page />,
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
          children: [
            { path: "/", element: <LandingPage /> },
            { path: "/about", element: <About /> },
            { path: "/skill", element: <Skills /> },
            { path: "/quotes", element: <Testimonial /> },
            { path: "/interview", element: <VideoSection /> },
            { path: "/opinie", element: <Reviews /> },
          ],
        },
        {
          path: "oferta",
          element: <Services />,
          children: [
            { path: "", element: <ServicesTiles /> },
            { path: "remont-wewnetrzny", element: <Service /> },
            { path: "elewacja-budynku", element: <Service /> },
            { path: "kostka-brukowa", element: <Service /> },
            { path: "ogrod", element: <Service /> },
            { path: "kamienny-dywan", element: <Service /> },
            { path: "ogrodzenia", element: <Service /> },
            { path: "elektryka", element: <Service /> },
            { path: "inteligentny-dom", element: <Service /> },
            { path: "fotowoltaika-i-pompy-ciepla", element: <Service /> },
            { path: "kotly-i-piece", element: <Service /> },
          ],
        },
        {
          path: "galeria",
          element: <Gallery />,
          children: [
            { path: "", element: <GalleryTiles /> },
            { path: "remont-wewnetrzny", element: <GalleryItem /> },
            { path: "ogrod", element: <GalleryItem /> },
            { path: "kostka-brukowa", element: <GalleryItem /> },
          ],
        },
        {
          path: "poradnik",
          element: <Blog />,
          children: [
            { path: "", element: <BlogList /> },
            { path: "jak-wykonac-ogrzewania-podlogowe", element: <BlogItem /> },
            { path: "jak-pomalowac-sciany-w-pokoju", element: <BlogItem /> },
            {
              path: "jak-dobrac-kolor-farby-do-wnetrza",
              element: <BlogItem />,
            },
            { path: "jak-ulozyc-kostke-brukowa", element: <BlogItem /> },
            { path: "jak-wybrac-kostke-brukowa", element: <BlogItem /> },
            { path: "jak-wymienic-lampe-w-pokoju", element: <BlogItem /> },
            {
              path: "jak-wymienic-gniazdko-elektryczne",
              element: <BlogItem />,
            },
            { path: "jak-docieplic-dom", element: <BlogItem /> },
          ],
        },
        { path: "faqs", element: <FAQs /> },
        { path: "kontakt", element: <ContactForm /> },
      ],
    },
  ],
  { basename: "/" },
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
