import { serviceData } from "../../data/data";
import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export const ServiceTicker = () => {
  return (
    <div
      className={
        "flex overflow-hidden [mask-image:linear-gradient(to_right,transparent,black,transparent)]"
      }
    >
      <motion.div
        className={"flex gap-16 pt-8 pb-0  pr-14 lg:gap-24"}
        animate={{ translateX: "-40%" }}
        transition={{
          duration: 25,
          repeat: Infinity,
          ease: "linear",
          repeatType: "loop",
        }}
      >
        {serviceData.map((service) => (
          <Link to={`${process.env.REACT_APP_API_BASE_URL}/${service.link}`}>
            <div className={"flex flex-col justify-start"}>
              <div
                className={"p-4 lg:text=5xl text-black text-center  text-3xl"}
              >
                {service.icon}
              </div>
              <div className={"text-center"}>{service.title}</div>
            </div>
          </Link>
        ))}{" "}
        {serviceData.map((service) => (
          <Link to={`${process.env.REACT_APP_API_BASE_URL}/${service.link}`}>
            <div className={"flex flex-col justify-start"}>
              <div
                className={"p-4 lg:text=5xl text-black text-center  text-3xl"}
              >
                {service.icon}
              </div>
              <div className={"text-center"}>{service.title}</div>
            </div>
          </Link>
        ))}
        {serviceData.map((service) => (
          <Link to={`${process.env.REACT_APP_API_BASE_URL}/${service.link}`}>
            <div className={"flex flex-col justify-start"}>
              <div
                className={"p-4 lg:text=5xl text-black text-center  text-3xl"}
              >
                {service.icon}
              </div>
              <div className={"text-center"}>{service.title}</div>
            </div>
          </Link>
        ))}
      </motion.div>
    </div>
  );
};
