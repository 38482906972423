import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { contactData } from "../../data/data";
import { fadeIn } from "../../utils/varients";
import ContactForm from "../contact/ContactForm";

const About = () => {
  const aboutRef = useRef(null);
  const { pathname } = useLocation();
  const { formTitle, info, form } = contactData;

  useEffect(() => {
    if (pathname === "/about")
      aboutRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [pathname]);
  return (
    <div ref={aboutRef} className="w-full lg:pt-20">
      <section className="lg:py-16 ">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row gap-x-[70px] items-center ">
            <motion.div
              variants={fadeIn("right")}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: "some" }}
              className="flex flex-col lg:flex-row flex-1 w-full mb-10"
            >
              <div className="w-[100%] lg:h-[600px] h-[500px] bg-about bg-contain bg-no-repeat bg-center flex justify-center align-middle rounded-tl-[50px]"></div>
            </motion.div>
            <motion.div
              variants={fadeIn("left")}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: "some" }}
              className="flex-1 h-full justify-center"
            >
              <h2 className="h2 max-w-[370px]  lg:text-start text-center">
                {formTitle}
              </h2>{" "}
              <h3 className={"lg:mb-20 mb-10 lg:text-start text-center"}>
                Napisz w poniższym formularzu co potrzebujesz zrobić, a kontakt
                z Naszej strony otrzymasz w ciągu 24h.
              </h3>
              <div className="flex-1  flex justify-center items-center ">
                <ContactForm></ContactForm>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
