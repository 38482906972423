import React, { useRef } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/varients";
import { serviceData } from "../../data/data";
import HomeServiceCard from "../common/HomeServiceCard";

const ServicesHome = () => {
  const aboutRef = useRef(null);

  return (
    <div
      ref={aboutRef}
      className="w-full mt-20 container mx-auto  mb-0 pb-0 flex flex-col lg:flex-col gap-x-[70px] items-center "
    >
      <motion.div
        variants={fadeIn("up")}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: "some" }}
        className="flex-1 h-full"
      >
        <h2 className="h2 max-w-[370px] lg:mb-20 lg:text-start text-center">
          Nasza Oferta:
        </h2>
        <div className="flex   flex-wrap justify-center flex-[1 0 50%]  pt-0 xl:gap-8 gap-4 opacity-100 md:justify-center">
          {serviceData.map((service, index) => (
            <HomeServiceCard
              number={service.number}
              key={index}
              imgSrc={service.imgSrc}
              imgAlt={service.imgAlt}
              icon={service.icon}
              title={service.title}
              link={service.link}
              description={service.description}
            ></HomeServiceCard>
          ))}
        </div>
      </motion.div>
      <motion.div
        variants={fadeIn("down")}
        initial="show"
        whileInView={"show"}
        viewport={{ once: false, amount: "some" }}
        className="flex flex-col lg:flex-row flex-1 w-[100%] mt-8 md:mt-0 lg:w-screen "
      >
        <div className=" md:bg-fixed md:mt-8 n w-[100%] min-h-[500px] bg-homeServices bg-cover bg-no-repeat bg-center flex justify-center align-middle lg:rounded-tr-[0px] lg:rounded-bl-[0px] rounded-tr-[250px] rounded-bl-[50px] shadow-2xl"></div>
      </motion.div>
    </div>
  );
};

export default ServicesHome;
