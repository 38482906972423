import { Outlet } from "react-router-dom";
import { useEffect } from "react";

const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "Remonty Wieliczka Usługi, Oferta i Cennik, RemontBezPartaczy.pl";
  }, []);

  return <Outlet />;
};

export default Service;
