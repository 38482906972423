import RemontWewnetrzny1 from "../../asset/gallery/remont-wewnetrzny/remont-wewnetrzny-1.jpg";
import RemontWewnetrzny2 from "../../asset/gallery/remont-wewnetrzny/remont-wewnetrzny-2.jpg";
import RemontWewnetrzny3 from "../../asset/gallery/remont-wewnetrzny/remont-wewnetrzny-3.jpg";
import RemontWewnetrzny4 from "../../asset/gallery/remont-wewnetrzny/remont-wewnetrzny-4.jpg";
import RemontWewnetrzny1Thumbnail from "../../asset/gallery/remont-wewnetrzny/remont-wewnetrzny-1-thumbnail.jpg";
import RemontWewnetrzny2Thumbnail from "../../asset/gallery/remont-wewnetrzny/remont-wewnetrzny-2-thumbnail.jpg";
import RemontWewnetrzny3Thumbnail from "../../asset/gallery/remont-wewnetrzny/remont-wewnetrzny-3-thumbnail.jpg";
import RemontWewnetrzny4Thumbnail from "../../asset/gallery/remont-wewnetrzny/remont-wewnetrzny-4-thumbnail.jpg";
import Ogrod0Thumbnail from "../../asset/gallery/ogrod/ogrod-0-thumbnail.jpg";
import Ogrod1Thumbnail from "../../asset/gallery/ogrod/ogrod-1-thumbnail.jpg";
import Ogrod2Thumbnail from "../../asset/gallery/ogrod/ogrod-2-thumbnail.jpg";
import Ogrod3Thumbnail from "../../asset/gallery/ogrod/ogrod-3-thumbnail.jpg";
import Ogrod4Thumbnail from "../../asset/gallery/ogrod/ogrod-4-thumbnail.jpg";
import Ogrod5Thumbnail from "../../asset/gallery/ogrod/ogrod-5-thumbnail.jpg";
import Ogrod0 from "../../asset/gallery/ogrod/ogrod-0.jpg";
import Ogrod1 from "../../asset/gallery/ogrod/ogrod-1.jpg";
import Ogrod2 from "../../asset/gallery/ogrod/ogrod-2.jpg";
import Ogrod3 from "../../asset/gallery/ogrod/ogrod-3.jpg";
import Ogrod4 from "../../asset/gallery/ogrod/ogrod-4.jpg";
import Ogrod5 from "../../asset/gallery/ogrod/ogrod-5.jpg";
import KostkaBrukowa0Thumbnail from "../../asset/gallery/kostka-brukowa/kostka-brukowa-0-thumbnail.jpg";
import KostkaBrukowa1Thumbnail from "../../asset/gallery/kostka-brukowa/kostka-brukowa-1-thumbnail.jpg";
import KostkaBrukowa2Thumbnail from "../../asset/gallery/kostka-brukowa/kostka-brukowa-2-thumbnail.jpg";
import KostkaBrukowa3Thumbnail from "../../asset/gallery/kostka-brukowa/kostka-brukowa-3-thumbnail.jpg";
import KostkaBrukowa0 from "../../asset/gallery/kostka-brukowa/kostka-brukowa-0.jpg";
import KostkaBrukowa1 from "../../asset/gallery/kostka-brukowa/kostka-brukowa-1.jpg";
import KostkaBrukowa2 from "../../asset/gallery/kostka-brukowa/kostka-brukowa-2.jpg";
import KostkaBrukowa3 from "../../asset/gallery/kostka-brukowa/kostka-brukowa-3.jpg";

export const GalleryData = [
  {
    number: "01",
    imgSrc: RemontWewnetrzny1Thumbnail,
    imgAlt: "",
    title: "Remont Wewnętrzny",
    description: "",
    link: "galeria/remont-wewnetrzny",
    images: [
      {
        src: RemontWewnetrzny1Thumbnail,
        original: RemontWewnetrzny1,
        width: 550,
        height: 500,
      },
      {
        src: RemontWewnetrzny2Thumbnail,
        original: RemontWewnetrzny2,
        width: 665,
        height: 440,
      },
      {
        src: RemontWewnetrzny3Thumbnail,
        original: RemontWewnetrzny3,
        width: 450,
        height: 680,
      },
      {
        src: RemontWewnetrzny4Thumbnail,
        original: RemontWewnetrzny4,
        width: 865,
        height: 540,
      },
    ],
  },
  {
    number: "02",
    imgSrc: Ogrod5Thumbnail,
    imgAlt: "",
    title: "Ogród",
    description: "",
    link: "galeria/ogrod",
    images: [
      {
        src: Ogrod1Thumbnail,
        original: Ogrod1,
        width: 1200,
        height: 800,
      },
      {
        src: Ogrod0Thumbnail,
        original: Ogrod0,
        width: 1200,
        height: 800,
      },
      {
        src: Ogrod2Thumbnail,
        original: Ogrod2,
        width: 1200,
        height: 800,
      },
      {
        src: Ogrod3Thumbnail,
        original: Ogrod3,
        width: 1200,
        height: 800,
      },
      {
        src: Ogrod4Thumbnail,
        original: Ogrod4,
        width: 1200,
        height: 800,
      },
      {
        src: Ogrod5Thumbnail,
        original: Ogrod5,
        width: 550,
        height: 800,
      },
    ],
  },
  {
    number: "03",
    imgSrc: KostkaBrukowa3Thumbnail,
    imgAlt: "",
    title: "Kostka Brukowa",
    description: "",
    link: "galeria/kostka-brukowa",
    images: [
      {
        src: KostkaBrukowa0Thumbnail,
        original: KostkaBrukowa0,
        width: 800,
        height: 1000,
      },
      {
        src: KostkaBrukowa1Thumbnail,
        original: KostkaBrukowa1,
        width: 800,
        height: 1000,
      },
      {
        src: KostkaBrukowa2Thumbnail,
        original: KostkaBrukowa2,
        width: 800,
        height: 1000,
      },
      {
        src: KostkaBrukowa3Thumbnail,
        original: KostkaBrukowa3,
        width: 800,
        height: 1000,
      },
    ],
  },
];
