import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/varients";
import { heroData } from "../../data/data";
import { Helmet } from "react-helmet";

const LandingPage = () => {
  const { pathname } = useLocation();
  const landingRef = useRef(null);

  const mobileScreen = useMediaQuery({
    query: "(max-width: 640px)",
  });
  const mobileFixed = useMediaQuery({
    query: "(max-width: 1000px)",
  });

  const structuredJSON = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Usługi remontowe Wieliczka",
    image:
      "https://remontbezpartaczy.pl/static/media/xremont-bez-partaczy-logo.6728b395300c330d49a2.webp.pagespeed.ic.TJYKUjq60X.webp",
    "@id": "",
    url: "https://remontbezpartaczy.pl/",
    telephone: "+48 696 939 848",
    address: {
      "@type": "PostalAddress",
      streetAddress: "",
      addressLocality: "Wieliczka",
      postalCode: "30-663,",
      addressCountry: "PL",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 49.986799941793244,
      longitude: 20.06343620810141,
    },
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      opens: "00:00",
      closes: "23:59",
    },
    sameAs: [
      "https://www.facebook.com/profile.php?id=61561845731127",
      "https://www.instagram.com/remontbezpartaczy_pl/?igsh=cHV4aWZ2Nms3bzZ0",
      "https://www.youtube.com/@RemontBezPartaczy-pl",
    ],
  };

  useEffect(() => {
    if (pathname === "/")
      landingRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>
          Usługi Remontowe i Wykończeniowe Wieliczka RemontBezPartaczy.pl
        </title>
        <script className="structured-data-list" type="application/ld+json">
          {`${JSON.stringify(structuredJSON)}`}
        </script>
      </Helmet>
      <div
        ref={landingRef}
        className={
          "h-screen w-full relative flex flex-col items-center justify-between text-white bg-cover bg-fixed bg-bottom landing-bg " +
          (mobileScreen ? "bg-landingPageMobile " + " " : " bg-landingPage ") +
          (mobileFixed ? " bg-center bg-scroll " : "  ")
        }
      >
        <main className="z-30 flex flex-1 flex-col items-stretch justify-end w-full gap-4 xl:gap-10 p-[20px] xl:p-[60px] h-screen xl:max-w-[50%] self-start lg:ml-[30px]">
          <motion.h1
            variants={fadeIn("right")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.6 }}
            className="lg:text-[4rem]  md:text-[2rem]  text-[2rem]  font-bold w-full  text-center sm:text-left"
          >
            RemontBezPartaczy.pl
          </motion.h1>
          <motion.h2
            variants={fadeIn("right")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.1 }}
            className="text-lg xl:text-2xl font-light pb-8 text-center sm:text-left"
          >
            Zrzeszamy najlepszych wykonawców w wielu dziedzinach budowlanych.
          </motion.h2>
          <motion.div
            variants={fadeIn("up")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: "some" }}
            className="max-w-[530px] text-grey mb-16 md:mb-0 lg:mb-0"
          >
            <div className={"flex justify-center sm:justify-start"}>
              <Link
                to={`${process.env.REACT_APP_API_BASE_URL}/oferta`}
                relative={"path"}
              >
                <button
                  aria-label="Zobacz więcej"
                  className="btn btn-lg btn-link hover:bg-white hover:text-black text-white  border-[2px] p-3 rounded-lg border-white/80 animate-pulse mb-7 mr-3"
                >
                  Zobacz więcej
                  <div className="text-xl">{heroData.btnIcon}</div>
                </button>
              </Link>

              <Link to={`${process.env.REACT_APP_API_BASE_URL}/kontakt`}>
                <button
                  className="btn btn-lg btn-link hover:bg-transparent hover:text-white text-black p-3 border-[2px]  bg-white  rounded-lg border-white/80 mb-7 "
                  aria-label="Kontakt"
                >
                  Kontakt
                </button>
              </Link>
            </div>
          </motion.div>
        </main>
      </div>
    </>
  );
};

export default LandingPage;
