import { Outlet } from "react-router-dom";
import { useEffect } from "react";

const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <Outlet />;
};

export default Gallery;
