import React, { useEffect, useRef, useState } from "react";
import { footerData } from "../../data/data";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/varients";
import { Link, useLocation } from "react-router-dom";
import Socials from "../TopBar/Social";
import * as emailjs from "emailjs-com";

const staggerContainer = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.3,
      delayChildren: 0.2,
    },
  },
};

const Footer = () => {
  const { about, links, program, newsletter } = footerData;

  const footerRef = useRef(null);
  const formRef = useRef();

  const [phone, setPhone] = useState("");

  const phoneChange = (event) => {
    let val = event.target.value;
    val = val.replace(/ /gm, "");
    let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, 9)}`;
    num = num.trim();
    setPhone(num);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!phone) {
      alert("Uzupełnij numer telefonu");
      return;
    }

    emailjs
      .sendForm(
        "service_2yhqbwj",
        "template_4ewzeop",
        formRef.current,
        "a_AEt5ll_-ZZXnpm3",
      )
      .then(
        () => {
          setPhone("");
          window.alert(
            "Dziękujemy za pozostawienie numeru. Postaramy się jak najszybciej odpowiedzieć.",
          );
        },
        (error) => {
          console.log(error);
        },
      );
  };

  return (
    <footer ref={footerRef} className="bg-dark section">
      <div className="container mx-auto">
        {/* grid */}
        <motion.div
          variants={staggerContainer}
          initial={"hidden"}
          whileInView={"show"}
          viewport={{ once: false, amount: 0.1 }}
          className="flex flex-col lg:flex-row lg:justify-between text-white gap-x-5 gap-y-14"
        >
          {/* about */}
          <motion.div
            variants={fadeIn("up")}
            className="flex-1 flex flex-col gap-y-6"
          >
            {/* title */}
            <div className="font-primary text-xl uppercase tracking-[0.08em]">
              {about.title}
            </div>
            {/* subtitle */}
            <div className="leading-relaxed text-[#DBDBDB]">
              {about.subtitle}
            </div>
            {/* address & phone & email */}
            <div className="flex flex-col gap-y-4 font-semibold text-[#DBDBDB]">
              {/* address */}
              <div className="flex items-center gap-x-[10px]">
                <div>{about.address.icon}</div>
                <div>{about.address.name}</div>
              </div>
              {/* phone */}
              <div className="flex items-center gap-x-[10px]">
                <div>{about.phone.icon}</div>
                <div>
                  <a href={`tel:${about.phone.number}`}>{about.phone.number}</a>
                </div>
              </div>
              {/* email */}
              <div className="flex items-center gap-x-[10px]">
                <div>{about.email.icon}</div>
                <div>
                  <a href={`mailto:${about.email.address}`}>
                    {about.email.address}
                  </a>
                </div>
              </div>
            </div>
            <div className={"w-fit mt-6"}>
              <Socials></Socials>
            </div>
          </motion.div>
          {/* links */}
          <motion.div
            variants={fadeIn("up")}
            className="flex-1 flex flex-col xl:items-center"
          >
            <div>
              <div className="font-primary text-xl uppercase tracking-[0.08em] mb-6">
                {links.title}
              </div>
              <ul className="flex flex-col gap-y-4 text-[#DBDBDB]">
                {links.items.map((item, index) => {
                  // destructure item
                  const { href, name } = item;
                  return (
                    <Link
                      to={`${process.env.REACT_APP_API_BASE_URL}${href}`}
                      key={index}
                    >
                      <p className="hover:text-white transition">{name}</p>
                    </Link>
                  );
                })}
              </ul>
            </div>
          </motion.div>
          {/* program */}
          <motion.div variants={fadeIn("up")} className="flex-1">
            <div className="font-primary text-xl uppercase tracking-[0.08em] mb-6">
              {program.title}
            </div>
            <ul className="flex flex-col gap-y-4 text-[#DBDBDB]">
              {program.items.map((item, index) => {
                return <li key={index}>{item.name}</li>;
              })}
            </ul>
          </motion.div>
          {/* newsletter */}
          <motion.div variants={fadeIn("up")} className="flex-1">
            <div className="font-primary text-xl uppercase tracking-[0.08em] mb-6">
              {newsletter.title}
            </div>
            <div className="leading-relaxed mb-9 text-[#DBDBDB]">
              {newsletter.subtitle}
            </div>
            <form
              ref={formRef}
              className="flex  justify-between items-start border-b border-[#B6B6B6]"
              onSubmit={handleSubmit}
            >
              <input
                className="outline-none placeholder:text-base italic placeholder:capitalize  bg-transparent pb-2"
                type="text"
                placeholder={newsletter.form.placeholder}
                name="phone"
                value={phone}
                maxLength={15}
                onChange={(e) => {
                  phoneChange(e);
                }}
              />
              <button
                type="submit"
                className="text-2xl cursor-pointer"
                aria-label="Newsletter"
              >
                {newsletter.form.icon}
              </button>
            </form>
          </motion.div>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;
