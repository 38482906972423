import React, { useEffect, useRef, useState } from "react";
import * as emailjs from "emailjs-com";
import { contactData } from "../../data/data";

const ContactForm = () => {
  const { info, form } = contactData;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const formRef = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const phoneChange = (event) => {
    let val = event.target.value;
    val = val.replace(/ /gm, "");

    let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, 9)}`;

    num = num.trim();

    setPhone(num);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!name || !email || !phone || !message) {
      alert("Uzupełnij wszystkie pola.");
      return;
    }

    emailjs
      .sendForm(
        "service_2yhqbwj",
        "template_4ewzeop",
        formRef.current,
        "a_AEt5ll_-ZZXnpm3",
      )
      .then(
        () => {
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          window.alert(
            "Dziękujemy za wiadomość. Postaramy się jak najszybciej odpowiedzieć.",
          );
        },
        (error) => {
          console.log(error);
        },
      );
  };

  return (
    <form
      className="flex flex-col gap-y-10 w-full"
      id="contact-form"
      ref={formRef}
      onSubmit={handleSubmit}
    >
      <input
        className="border-b border-dark placeholder:text-[#555] italic tracking-[0.06em] outline-none pb-4"
        type="text"
        name="name"
        placeholder={form.name}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <input
        className="border-b border-dark placeholder:text-[#555] italic tracking-[0.06em] outline-none pb-4"
        type="email"
        name="email"
        placeholder={form.email}
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <input
        className="border-b border-dark placeholder:text-[#555] italic tracking-[0.06em] outline-none pb-4 xl:mb-[30px]"
        type="text"
        name="phone"
        placeholder={form.phone}
        value={phone}
        maxLength={15}
        onChange={(e) => {
          phoneChange(e);
        }}
      />{" "}
      <input
        className="border-b border-dark placeholder:text-[#555] italic tracking-[0.06em] outline-none pb-4 xl:mb-[30px]"
        type="text"
        name="message"
        placeholder={form.message}
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      />
      <button
        type="submit"
        className="btn btn-sm btn-dark self-center lg:self-start"
        aria-label="Wyślij"
      >
        {form.btnText}
      </button>
    </form>
  );
};

export default ContactForm;
