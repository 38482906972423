import OgrzewaniePodlogowe from "../asset/blog/ogrzewania-podlogowe.webp";
import JakPomwalowacScianyWpokoju from "../asset/blog/jak-pomwalowac-sciany-w-pokoju.webp";
import JakDobracFarbe from "../asset/blog/jak-dobrac-kolor-farby-do-wnetrza.webp";
import JakUlozycKostkeBrukowa from "../asset/services/serviceTiles/kostka-brukowa-wieliczka-rpb.webp";
import JakWybracKostkeBrukowa from "../asset/services/serviceTiles/kamienny-dywan-wieliczka-rbp.webp";
import JakDobracFotowoltaike from "../asset/services/serviceTiles/fotowoltaika-i-pompny-ciepla-wieliczka-rbp.webp";
import JakDocieplicDom from "../asset/services/serviceTiles/elewacje-wieliczka-rbp.webp";
import JakWymienicLampeWPokoju from "../asset/blog/jak-wymienic-lampe-w-pokoju.webp";
import JakWymienicGniazdkoElektryczne from "../asset/blog/jak-wymienic-gniazdko-elektryczne.webp";
import ZCzegoSkladaSieInstalacjaFoto from "../asset/gallery/fotowoltaika/fotowoltaika-0-thumbnail.jpg";
import JakDobracPompeCiepla from "../asset/gallery/pompy-ciepla/pompy-ciepla-1-thumbnail.jpg";
import JakDobracPompePiec from "../asset/gallery/kotly-i-piece/kotly-i-piece-1-thumbnail.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHammer } from "@fortawesome/free-solid-svg-icons";
import { blogDescriptions } from "./blogDescriptions";

export const blogData = [
  {
    number: "01",
    imgSrc: OgrzewaniePodlogowe,
    title: "Jak Wykonać Ogrzewanie Podłogowe?",
    imgAlt: "Jak Wykonać Ogrzewanie Podłogowe Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faHammer} />,
    description: blogDescriptions[0].description,
    link: "poradnik/jak-wykonac-ogrzewania-podlogowe",
  },
  {
    number: "02",
    imgSrc: JakPomwalowacScianyWpokoju,
    title: "Jak Pomalować Ściany w Pokoju?",
    imgAlt: "Jak Pomalować Ściany w Pokoju Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faHammer} />,
    description: blogDescriptions[1].description,
    link: "poradnik/jak-pomalowac-sciany-w-pokoju",
  },
  {
    number: "03",
    imgSrc: JakDobracFarbe,
    title: "Jak Dobrać Kolor Farby do Wnętrza?",
    imgAlt: "Jak Dobrać Kolor Farby do Wnętrza Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faHammer} />,
    description: blogDescriptions[2].description,
    link: "poradnik/jak-dobrac-kolor-farby-do-wnetrza",
  },
  {
    number: "04",
    imgSrc: JakUlozycKostkeBrukowa,
    title: "Jak Ułożyć Kostkę Brukową?",
    imgAlt: "Jak Ułożyć Kostkę Brukową Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faHammer} />,
    description: blogDescriptions[3].description,
    link: "poradnik/jak-ulozyc-kostke-brukowa",
  },
  {
    number: "05",
    imgSrc: JakWybracKostkeBrukowa,
    title: "Jak Wybrać Kostkę Brukową?",
    imgAlt: "Jak Wybrać Kostkę Brukową Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faHammer} />,
    description: blogDescriptions[4].description,
    link: "poradnik/jak-wybrac-kostke-brukowa",
  },
  {
    number: "06",
    imgSrc: JakWymienicLampeWPokoju,
    title: "Jak Wymienić Lampę w Pokoju?",
    imgAlt: "Jak Wymienić Lampę w Pokoju? Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faHammer} />,
    description: blogDescriptions[5].description,
    link: "poradnik/jak-wymienic-lampe-w-pokoju",
  },
  {
    number: "07",
    imgSrc: JakWymienicGniazdkoElektryczne,
    title: "Jak Wymienić Gniazdo Elektryczne?",
    imgAlt: "Jak Wymienić Gniazdo Elektryczne Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faHammer} />,
    description: blogDescriptions[6].description,
    link: "poradnik/jak-wymienic-gniazdko-elektryczne",
  },
  {
    number: "08",
    imgSrc: JakDocieplicDom,
    title: "Jak Docieplić Dom?",
    imgAlt: "Jak Docieplić Dom Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faHammer} />,
    description: blogDescriptions[7].description,
    link: "poradnik/jak-docieplic-dom",
  },
];
