import { ElfsightWidget } from "react-elfsight-widget";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/varients";
import React from "react";

const Reviews = () => {
  return (
    <div className="section lg:mt-0 mt-12 lg:pt-20 pb-0">
      <div className="container mx-auto ">
        <motion.h2
          variants={fadeIn("up")}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.6 }}
          className="h2 max-w-[370px] lg:mb-10 lg:text-start text-center"
        >
          Nasze opinie
        </motion.h2>
      </div>
      <div className={"lg:mx-0 mx-10"}>
        <ElfsightWidget
          widgetId={"cc0c2e18-fe84-42e1-b8a7-417c1b49f006"}
          lazy
        />
        <div
          className={"w-screen top-[-40px] z-10 h-[40px] bg-white relative "}
        ></div>
      </div>
    </div>
  );
};
export default Reviews;
