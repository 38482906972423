import React, { useEffect } from "react";
import { fadeIn } from "../../utils/varients";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { blogData } from "../../data/blogData";
import { Helmet } from "react-helmet";
import { IoMdArrowForward } from "react-icons/io";

const BlogItem = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { pathname } = useLocation();
  const blog = blogData.find((service) => pathname.slice(1) === service.link);
  return (
    <>
      <Helmet>
        <title>{blog.title} Wieliczka Kraków</title>
      </Helmet>
      <div className="section lg:mt-0 mt-24">
        <div className="container mx-auto">
          <motion.h2
            variants={fadeIn("up")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="h2 max-w-[370px] lg:mb-20 flex xl:justify-start justify-center lg:mx-16 mx-10 lg:text-6xl lg:text-start text-2xl text-center"
          >
            {blog.title}
          </motion.h2>
        </div>
        <motion.div
          variants={fadeIn("up")}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: "some" }}
          className="flex flex-row flex-wrap justify-center pt-0 xl:gap-8 gap-4 opacity-100 container mx-auto"
        >
          <p
            className={"lg:mx-16 mx-10"}
            dangerouslySetInnerHTML={{ __html: blog.description }}
          />
          <Link to={`${process.env.REACT_APP_API_BASE_URL}/oferta`}>
            <button
              className="btn btn-sm btn-dark self-center"
              aria-label="Zobacz więcej"
            >
              Zobacz naszą ofertę
              <div className="text-xl">
                <IoMdArrowForward />
              </div>
            </button>
          </Link>
        </motion.div>
      </div>
    </>
  );
};
export default BlogItem;
