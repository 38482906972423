import { motion } from "framer-motion";
import { fadeIn } from "../../utils/varients";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { serviceData } from "../../data/data";
import { Helmet } from "react-helmet";

const Service = () => {
  const { pathname } = useLocation();
  const service = serviceData.find(
    (service) => pathname.slice(1) === service.link,
  );

  const structuredJSON = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Usługi remontowe Wieliczka",
    image:
      "https://remontbezpartaczy.pl/static/media/xremont-bez-partaczy-logo.6728b395300c330d49a2.webp.pagespeed.ic.TJYKUjq60X.webp",
    "@id": "",
    url: "https://remontbezpartaczy.pl/",
    telephone: "+48 696 939 848",
    address: {
      "@type": "PostalAddress",
      streetAddress: "",
      addressLocality: "Wieliczka",
      postalCode: "30-663,",
      addressCountry: "PL",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 49.986799941793244,
      longitude: 20.06343620810141,
    },
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      opens: "00:00",
      closes: "23:59",
    },
    sameAs: [
      "https://www.facebook.com/profile.php?id=61561845731127",
      "https://www.instagram.com/remontbezpartaczy_pl/?igsh=cHV4aWZ2Nms3bzZ0",
      "https://www.youtube.com/@RemontBezPartaczy-pl",
    ],
  };

  useEffect(() => {
    document.title = `${service.pageTitle}`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{service.pageTitle}</title>

        <script className="structured-data-list" type="application/ld+json">
          {`${JSON.stringify(structuredJSON)}`}
        </script>
      </Helmet>
      <div className="section lg:mt-0 mt-24">
        <div className="container mx-auto">
          <motion.h1
            variants={fadeIn("up")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.6 }}
            className="h2 max-w-[370px] lg:mb-18 flex lg:justify-start justify-center text-center lg:text-start"
          >
            {service.title} Wieliczka
          </motion.h1>
        </div>
        <motion.img
          variants={fadeIn("right")}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.1 }}
          className={
            "w-[80%] justify-center self-center mx-auto my-16 shadow-2xl rounded-2xl max-h-[50rem]"
          }
          src={service.img1Src}
          alt={service.imgAlt}
        />
        <motion.div
          variants={fadeIn("up")}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: "some" }}
          className="flex flex-row flex-wrap justify-center pt-0 xl:gap-8 gap-4 opacity-100 container mx-auto"
        >
          <p
            className={"mx-19"}
            dangerouslySetInnerHTML={{ __html: service.description }}
          />
          <Link to={`${process.env.REACT_APP_API_BASE_URL}/kontakt`}>
            <button
              type="submit"
              className="btn btn-sm btn-dark self-center lg:self-start"
              aria-label="Wyślij"
            >
              Skontaktuj się z nami!
            </button>{" "}
          </Link>
        </motion.div>
      </div>
    </>
  );
};

export default Service;
