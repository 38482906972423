import { Link } from "react-router-dom";

const Card = (props) => {
  return (
    <Link
      to={`${process.env.REACT_APP_API_BASE_URL}/${props.link}`}
      aria-label="Read more "
    >
      <div
        className={
          "relative max-w-xs overflow-hidden rounded-2xl shadow-xl group cursor-pointer h-96"
        }
      >
        <img
          src={props.imgSrc}
          alt={props.imgAlt}
          className={"transition-transform group-hover:scale-110 duration-500 "}
        />
        <div
          className={
            "absolute inset-0 flex items-end bg-gradient-to-t from-black/80 to-transparent"
          }
        >
          <div className={"p-4 text-white"}>
            <h2 className={"text-3xl"}>{props.title}</h2>
            <p className={"font-light text-sm"}></p>
            <div className={"space-x-4 mt-4"}></div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
