import React, { useState, useRef, useEffect } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/varients";
import { Link, useLocation } from "react-router-dom";
import { GalleryData } from "../../data/galleryData/galleryData";
import { IoMdArrowDown } from "react-icons/io";
import { Helmet } from "react-helmet";

const GallerySection = () => {
  const { pathname } = useLocation();
  const galleryData = GalleryData.find(
    (galleryData) => pathname.slice(1) === galleryData.link,
  );

  const slides = galleryData.images.map(({ original, width, height }) => ({
    src: original,
    width,
    height,
  }));

  const [index, setIndex] = useState(-1);
  const displayPhoto = (event) => {
    setIndex(event.index);
  };

  const { title, images } = galleryData;

  const galleryRef = useRef(null);
  useEffect(() => {
    if (pathname === "/gallery")
      galleryRef.current?.scrollIntoView({ behavior: "smooth" });
    document.title = `${galleryData.title} Aranżacje | Wieliczka Kraków | RemontBezPartaczy.pl`;
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>
          {galleryData.title} Aranżacje | Wieliczka Kraków |
          RemontBezPartaczy.pl
        </title>
        <link
          rel="canonical"
          href={`https://remontbezpartaczy.pl/galeria/${galleryData.link}`}
        />
      </Helmet>
      <section
        ref={galleryRef}
        className="bg-[#F9F9F9] section relative px-7 md:px-10 lg:mt-0  mt-24"
      >
        <div className="container mx-auto flex lg:justify-start justify-center">
          <motion.h2
            variants={fadeIn("up")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.4 }}
            className="h2 max-w-[370px] lg:mt-16 lg:mb-20 flex xl:text-start text-center"
          >
            {title}
          </motion.h2>
        </div>
        {/* photo album */}
        <motion.div
          variants={fadeIn("up")}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.2 }}
          className="mb-8 lg:mb-20"
        >
          <PhotoAlbum layout="rows" photos={images} onClick={displayPhoto} />
          <Lightbox
            slides={slides}
            styles={{ container: { backgroundColor: "rgba(0,0,0,.9)" } }}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
          />
        </motion.div>
        {/* btn */}
        <motion.div
          variants={fadeIn("up")}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.2 }}
          className="flex justify-center"
        >
          <Link to={`${process.env.REACT_APP_API_BASE_URL}/oferta`}>
            <button className="btn btn-lg btn-dark" aria-label="Zobacz więcej">
              Zobacz więcej
              <div className="text-xl">
                <IoMdArrowDown />
              </div>
            </button>
          </Link>
        </motion.div>
      </section>
    </>
  );
};

export default GallerySection;
