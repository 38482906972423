import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/varients";
import { Link } from "react-router-dom";

const Description = () => {
  return (
    <div className="w-full pt-10">
      <section className="lg:py-16 lg:pb-0">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row gap-x-[70px] items-center ">
            <motion.div
              variants={fadeIn("right")}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: "some" }}
              className="flex flex-col lg:flex-row flex-1 w-full "
            >
              <div className="w-[100%] flex justify-center flex-col align-middle rounded-tl-[50px]">
                <h3 className={"text-lg text-center md:text-start"}>
                  <strong>RemontBezPartaczy.pl</strong> to profesjonalna firma
                  remontowa, która świadczy usługi remontowe na terenie
                  województwa Małopolskiego. Specjalizujemy się w remontach
                  mieszkań, domów, biur oraz lokali użytkowych.
                  <br /> <br /> <strong>Nasze usługi obejmują:</strong>
                </h3>
                <br />
                <ul
                  className={
                    "list-none md:list-disc lg:ml-12 flex flex-col md:justify-center"
                  }
                >
                  <li className={"text-center md:text-start mb-4"}>
                    Proces wykończenia pokoi i łazienek{" "}
                  </li>
                  <li className={"text-center md:text-start mb-4"}>
                    Prace remontowe wewnątrz budynku{" "}
                  </li>
                  <li className={"text-center md:text-start mb-4"}>
                    Prace remontowe wewnątrz budynku{" "}
                  </li>
                  <li className={"text-center md:text-start mb-4"}>
                    Tworzenie oraz pielęgnacja ogrodu{" "}
                  </li>
                  <li className={"text-center md:text-start mb-4"}>
                    Docieplanie lub samo malowanie i odświeżanie elewacji
                    zewnętrznej
                  </li>
                  <li className={"text-center md:text-start"}>
                    Tworzenie systemu inteligentnego domu
                  </li>
                </ul>
                <br />
                <Link to={`${process.env.REACT_APP_API_BASE_URL}/oferta`}>
                  <button
                    type="submit"
                    className="btn btn-sm flex btn-dark self-center px-24 mx-auto"
                    aria-label="Wyślij"
                  >
                    Zobacz ofertę
                  </button>
                </Link>
                <br />
                <h4 className={"text-lg text-center md:text-start"}>
                  Dzięki zespołowi fachowców świadczymy najwyższą jakość usług.
                  Każdego dnia otrzymasz „Raport z Remontu” na swoją pocztę
                  e-mail, w którym opisujemy pracę wykonaną każdego dnia oraz
                  przesyłamy Ci fotografie. Skontaktuj się z nami już dziś i
                  sprawdź, dlaczego warto wybrać RemontBezPartaczy.pl
                </h4>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Description;
