import Card from "../common/Card";
import { serviceData } from "../../data/data";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/varients";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { IoMdArrowForward } from "react-icons/io";

const ServicesTiles = () => {
  useEffect(() => {
    document.title =
      "Remonty Wieliczka Usługi, Oferta i Cennik, RemontBezPartaczy.pl";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {" "}
      <Helmet>
        <title>
          Remonty Wieliczka Usługi, Oferta i Cennik, RemontBezPartaczy.pl
        </title>
      </Helmet>
      <div className="section lg:mt-0 mt-24">
        <motion.div
          variants={fadeIn("down")}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.6 }}
          className="container mx-auto"
        >
          <h2 className="h2 max-w-[370px] lg:mb-10 flex lg:justify-start justify-center">
            Nasza Oferta
          </h2>
          <h3
            className={
              "text-lg text-center md:text-start mb-6 lg:mb-10 container"
            }
          >
            <strong>RemontBezPartaczy.pl</strong> to profesjonalna firma
            remontowa, która świadczy usługi remontowe na terenie województwa
            Małopolskiego. Specjalizujemy się w remontach mieszkań, domów, biur
            oraz lokali użytkowych.
            <br /> <br />
            Nasze usługi obejmują:
          </h3>
        </motion.div>
        <motion.div
          variants={fadeIn("up")}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: "some" }}
          className="mb-8 lg:mb-20  flex flex-row flex-wrap justify-center pt-0 xl:gap-8 gap-4 opacity-100 container mx-auto"
        >
          {serviceData.map((service, index) => (
            <Card
              key={index}
              imgSrc={service.imgSrc}
              imgAlt={service.imgAlt}
              title={service.title}
              link={service.link}
              description={service.description}
            ></Card>
          ))}
        </motion.div>
        <Link
          to={`${process.env.REACT_APP_API_BASE_URL}/galeria`}
          className={"w-full flex justify-center"}
        >
          <button className="btn btn-lg btn-dark" aria-label="Zobacz więcej">
            Zobacz nasze realizacje
            <div className="text-xl">
              <IoMdArrowForward />
            </div>
          </button>
        </Link>
      </div>
    </>
  );
};
export default ServicesTiles;
