import React from "react";
import { navData } from "../../data/data";
import Socials from "./Social";
import { Link } from "react-router-dom";

const NavMobile = () => {
  const { items } = navData;
  return (
    <nav className="w-full h-full flex flex-col justify-evenly overflow-hidden">
      <ul className="flex flex-col justify-center items-center gap-y-6 py-4 mb-8">
        {items.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className="text-2xl font-primary uppercase"
                to={`${process.env.REACT_APP_API_BASE_URL}${item.href}`}
              >
                {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
      <div className="text-2xl">
        <Socials />
      </div>
    </nav>
  );
};

export default NavMobile;
