import { Link } from "react-router-dom";
import React from "react";

const Error404Page = () => {
  return (
    <>
      <div
        className={
          "h-screen w-screen flex justify-center items-center flex-col"
        }
      >
        <h1 className={"text-2xl mb-10"}>Nie znaleziono strony</h1>
        <Link to={`${process.env.REACT_APP_API_BASE_URL}`}>
          <button
            type="submit"
            className="btn btn-sm btn-dark self-center lg:self-start"
            aria-label="Wyślij"
          >
            Idź strony RemontBezPartaczy.pl
          </button>{" "}
        </Link>
      </div>
    </>
  );
};
export default Error404Page;
