import React, { useState, useEffect, useRef } from "react";
import { videoSectionData } from "../../data/data";
import ModalVideo from "react-modal-video";
import "../../modalVideo.scss";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/varients";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const VideoSection = () => {
  const { title, btnText, btnIcon } = videoSectionData;
  const [isOpen, setOpen] = useState(false);
  const intervewRef = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/interview")
      intervewRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [pathname]);

  const mobileScreen = useMediaQuery({
    query: "(max-width: 640px)",
  });

  return (
    <div ref={intervewRef}>
      <motion.section
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.1 }}
        className={
          "section bg-dark bg-videoSection bg-no-repeat bg-cover bg-top bg-fixed lg:h-[812px]" +
          (mobileScreen
            ? "bg-videoSectionMobile" + " bg-center bg-scroll"
            : "bg-videoSection")
        }
      >
        <div className="container mx-auto h-full">
          <div className="flex flex-col justify-center h-full">
            <div className="flex flex-col items-start max-w-[880px]">
              <motion.h3
                variants={fadeIn("down")}
                className="text-white text-[20px] lg:text-[40px] leading-2 lg:leading-[1.2] mb-8 lg:text-left text-center"
              >
                {title}
              </motion.h3>
              {/* btn */}
              <motion.div variants={fadeIn("down")} className={"w-full"}>
                <div
                  className="text-white flex items-center gap-x-5 cursor-pointer hover:opacity-80 transition justify-center lg:justify-start"
                  onClick={() => setOpen(true)}
                >
                  {/* border */}
                  <div className="w-[70px] h-[70px] lg:w-[91px] lg:h-[91px] border border-white/40 rounded-full text-dark p-[5px] lg:p-[8px]">
                    {/* inner */}
                    <div className="bg-white w-full h-full rounded-full flex justify-center items-center">
                      <div className="pl-1 animate-pulse">{btnIcon}</div>
                    </div>
                  </div>
                  {/* btn text */}
                  <div className="font-primary uppercase tracking-[0.1em]">
                    {btnText}
                  </div>
                </div>
              </motion.div>
              {/* modal video */}
              <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="SXN1h7u-jZA"
                onClose={() => setOpen(false)}
              />
            </div>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default VideoSection;
