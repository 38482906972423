import React from "react";
import About from "./About";
import VideoSection from "./VideoSection";
import LandingPage from "./LandingPage";
import Skills from "./Skills";
import Testimonial from "./Testimonial";
import Reviews from "./Reviews";
import ServicesHome from "./ServicesHome";
import About1 from "./About1";
import { ServiceTicker } from "./ServiceTicker";
import Description from "./Description";

const index = () => {
  return (
    <>
      <LandingPage />
      <ServiceTicker />
      <Description />
      <About />
      <Skills />
      <Testimonial />
      <VideoSection />
      <ServicesHome />
      <About1 />
      <Reviews />
    </>
  );
};

export default index;
