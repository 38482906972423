import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { contactData } from "../../data/data";
import { fadeIn } from "../../utils/varients";
import ContactForm from "./ContactForm";
import Socials from "../TopBar/Social";
import { Helmet } from "react-helmet";

const Contact = () => {
  const { info, form } = contactData;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Usługi Remontowe i Wykończeniowe Wieliczka Kraków RemontBezPartaczy.pl
          Kontakt
        </title>
      </Helmet>
      <section className="section">
        <div className="container mx-auto">
          <h2 className="h2 max-w-[490px] mt-24 lg:mt-0 text-center lg:text-left">
            Napisz do nas:
          </h2>
          <div className="flex flex-col xl:flex-row gap-y-16">
            <motion.div
              variants={fadeIn("right")}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.4 }}
              className="flex-1"
            >
              <div className="flex flex-col xl:flex-row gap-x-5 gap-y-16 xl:gap-y-0">
                {info.map((item, index) => {
                  // destructure item
                  const {
                    title,
                    subtitle,
                    subtitle1,
                    address,
                    phone,
                    email,
                    link,
                    nip,
                  } = item;
                  return (
                    <div key={index}>
                      <div className="font-primary uppercase font-medium text-xl mb-3">
                        {title}
                      </div>
                      <div className="mb-6 text-[#333] leading-[187%] tracking-[0.02em]">
                        {subtitle}
                      </div>
                      <div className="flex flex-col gap-y-3 mb-8">
                        <div className="flex items-center gap-[10px]">
                          <div>{address.icon}</div>
                          <div className="font-medium">{address.name}</div>
                        </div>
                        <div className="flex items-center gap-[10px]">
                          <div>{phone.icon}</div>
                          <div className="font-medium">
                            <a href={`tel:${phone.number}`}>{phone.number}</a>
                          </div>
                        </div>
                        <div className="flex items-center gap-[10px]">
                          <div>{email.icon}</div>
                          <div className="font-medium">
                            <a href={`mailto:${email.address}`}>
                              {email.address}
                            </a>
                          </div>
                        </div>{" "}
                        <div className="flex items-center gap-[10px]">
                          <div>{nip.icon}</div>
                          <div className="font-medium">{nip.address}</div>
                        </div>
                        <div className={"mt-8 mb-6 justify-start w-fit"}>
                          <Socials></Socials>
                        </div>
                      </div>
                      <a
                        className="font-medium border-b border-dark pb-[5px]"
                        href="https://www.google.com/maps/dir//Kokot%C3%B3w+180/@50.0209884,20.0550416,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x471646a441a4605f:0xe143f1648798f07a!2m2!1d20.0962409!2d50.0209957!3e0!5m1!1e1?authuser=0&entry=ttu"
                        target={"_blank"}
                      >
                        {link}
                      </a>
                    </div>
                  );
                })}
              </div>
            </motion.div>
            <motion.div
              variants={fadeIn("left")}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.4 }}
              className="flex-1 xl:pl-[40px] flex justify-center items-center "
            >
              <ContactForm></ContactForm>
            </motion.div>
          </div>
        </div>
        <motion.div
          variants={fadeIn("left")}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.4 }}
          className="flex-1 justify-center container mx-auto lg:mt-20"
        >
          <h2 className="h2 max-w-[490px] mt-10 text-center lg:text-left">
            Znajdź nas:
          </h2>
          <iframe
            width="100%"
            height="600"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Kokot%C3%B3w%20180,%20Wieliczka+(RemontBezPartaczy.pl)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          >
            <a href="https://www.gps.ie/">gps systems</a>
          </iframe>
        </motion.div>
      </section>
    </>
  );
};

export default Contact;
