import GallerySection from "../Home/GallerySection";
import { useEffect } from "react";

const GalleryItem = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <GallerySection></GallerySection>;
};
export default GalleryItem;
