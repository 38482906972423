import React from "react";
import { Link, NavLink } from "react-router-dom";
import { navData } from "../../data/data";

const Nav = () => {
  const { items } = navData;

  return (
    <nav>
      <ul className="flex gap-x-[58px]">
        {items.map((item, index) => {
          return (
            <li key={index} className="font-medium ">
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending
                    ? "link hover:border-b-2 hover:border-dark transition duration-300"
                    : isActive
                      ? "link border-b-2 border-black transition duration-300"
                      : isActive
                        ? "link border-b-2 border-white transition duration-300 "
                        : ""
                }
                to={`${process.env.REACT_APP_API_BASE_URL}${item.href}`}
              >
                {item.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
