import QuoteImg from "../asset/testimonial/quote.svg";
import RemontWewnetrzny from "../asset/services/serviceTiles/remont-wewnetrzny-wieliczka-rbp.webp";
import Elewacje from "../asset/services/serviceTiles/elewacje-wieliczka-rbp.webp";
import Elewacje1 from "../asset/services/serviceTiles/elewacje-wieliczka-rbp1.webp";
import Ogrod from "../asset/services/serviceTiles/ogrod-wieliczka-rbp.webp";
import Ogrod1 from "../asset/gallery/ogrod/ogrod-3.jpg";
import KostkaBrukowa from "../asset/services/serviceTiles/kostka-brukowa-wieliczka-rpb.webp";
import KostkaBrukowa1 from "../asset/services/serviceTiles/kostka-brukowa-wieliczka-rpb1.webp";
import KamiennyDywan from "../asset/services/serviceTiles/kamienny-dywan-wieliczka-rbp.webp";
import Ogrodzenia from "../asset/services/serviceTiles/ogordzenia-wieliczka-rbp.webp";
import Elektryka from "../asset/services/serviceTiles/elektryka-wieliczka-rbp.webp";
import IntaligentnyDom from "../asset/services/serviceTiles/smart-home-wieliczka-rbp.webp";
import IntaligentnyDom1 from "../asset/services/serviceTiles/smart-home-wieliczka-rbp-1.webp";
import Fotowoltaika from "../asset/services/serviceTiles/fotowoltaika-i-pompny-ciepla-wieliczka-rbp.webp";
import Fotowoltaika1 from "../asset/services/serviceTiles/fotowoltaika-i-pompny-ciepla-wieliczka-rbp-1.webp";
import Piece from "../asset/gallery/kotly-i-piece/kotly-i-piece-1-thumbnail.jpg";
import Piece1 from "../asset/gallery/kotly-i-piece/kotly-i-piece-1.jpg";

import RemontWewnetrzny1 from "../asset/gallery/remont-wewnetrzny/remont-wewnetrzny-2.jpg";

import { GrFacebookOption } from "react-icons/gr";
import { IoMdArrowDown, IoMdContact } from "react-icons/io";
import { FiSend } from "react-icons/fi";
import {
  FaPlay,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaElementor,
} from "react-icons/fa";
import { IoLogoInstagram, IoLogoTwitter, IoLogoYoutube } from "react-icons/io";
import {
  faHammer,
  faBrush,
  faTrowelBricks,
  faSunPlantWilt,
  faRoad,
  faXmarksLines,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons/faLightbulb";
import { faSolarPanel } from "@fortawesome/free-solid-svg-icons/faSolarPanel";
import { faFireBurner } from "@fortawesome/free-solid-svg-icons/faFireBurner";
import { faHouseSignal } from "@fortawesome/free-solid-svg-icons/faHouseSignal";
import { IoLogoTiktok } from "react-icons/io5";

export const navData = {
  items: [
    { href: "/", name: "Home" },
    { href: "/oferta", name: "Oferta" },
    { href: "/galeria", name: "Galeria" },
    { href: "/poradnik", name: "Poradnik" },
    { href: "/faqs", name: "FAQs" },
    { href: "/kontakt", name: "Kontakt" },
  ],
};

export const socialData = [
  {
    href: "https://www.facebook.com/profile.php?id=61561845731127",
    icon: <GrFacebookOption />,
  },
  {
    href: "https://www.instagram.com/remontbezpartaczy_pl?igsh=cHV4aWZ2Nms3bzZ0",
    icon: <IoLogoInstagram />,
  },
  {
    href: "https://www.tiktok.com/@remontbezpartaczy.pl?lang=pl-PL",
    icon: <IoLogoTiktok />,
  },
  { href: "https://www.x.com", icon: <IoLogoTwitter /> },
  {
    href: "https://www.youtube.com/@RemontBezPartaczy-pl",
    icon: <IoLogoYoutube />,
  },
];

export const heroData = {
  title: "",
  subtitle: "",
  btnText: "",
  btnIcon: <IoMdArrowDown />,
  btnIcon2: <IoMdContact />,
};

export const serviceData = [
  {
    number: "01",
    imgSrc: RemontWewnetrzny,
    img1Src: RemontWewnetrzny1,
    title: "Remont Wewnętrzny",
    pageTitle:
      "Remonty Wieliczka | Usługi Remontowe Wieliczka | Usługi Wykończeniowe",
    imgAlt: "Remont wewnętrzny Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faHammer} />,
    description: ` <p style="text-align: justify">Remonty wewnętrzne to nasza specjalność! W Remontbezpartaczy.pl oferujemy kompleksowe usługi remontowe dostosowane do indywidualnych potrzeb każdego klienta. Niezależnie od tego, czy planujesz odświeżyć jedno pomieszczenie, czy przeprowadzić generalny remont całego mieszkania, nasz zesp&oacute;ł doświadczonych fachowc&oacute;w zapewni realizację na najwyższym poziomie.</p>
                  <p><br></p>
                   <h3 style="font-size: 1.5rem"><strong>Nasze Usługi Remontowe Obejmują: </strong><br><br></h3>
                  <p><br></p>
                  <p>&bull; <strong>Malowanie i tapetowanie ścian: </strong><br><br> Odświeżenie wnętrz z użyciem najwyższej jakości farb i tapet, kt&oacute;re gwarantują trwałość i estetykę.</p>
                  <p><br></p>
                  <p>&bull; <strong>Instalacje elektryczne i hydrauliczne:&nbsp;<br><br></strong>Modernizacja oraz naprawa instalacji, aby zapewnić bezpieczne i funkcjonalne użytkowanie.</p>
                  <p><br></p>
                  <p>&bull; <strong>Prace wykończeniowe: </strong><br><br> Gładzie, sztukateria, montaż listew przypodłogowych, sufit&oacute;w podwieszanych i inne detale, kt&oacute;re nadają wnętrzom wyjątkowego charakteru.</p>
                  <p><br></p>
                  <p>&bull; <strong>Remonty łazienek i kuchni: </strong><br><br> Kompleksowe przebudowy, w tym kafelkowanie, montaż armatury, mebli kuchennych i łazienkowych.</p>
                  <p><br></p>
                   <p><br></p>
                   <h3 style="font-size: 1.5rem"><strong>Dlaczego Warto Wybrać Remontbezpartaczy.pl?</strong></h3>
                  <p><br></p>
                  <p>&bull; <strong>I</strong><strong>ndywidualne podejście: </strong><br><br> Każdy projekt traktujemy z pełnym zaangażowaniem, dostosowując nasze usługi do Twoich oczekiwań i potrzeb.</p>
                  <p><br></p>
                   <p>&bull; <strong>Profesjonalizm i doświadczenie: </strong><br><br> Zrzeszamy wykwalifikowanych specjalist&oacute;w z wieloletnim doświadczeniem w branży remontowej.</p>
                   <p><br><br></p>
                  <p >Zaufaj ekspertom i ciesz się pięknym, funkcjonalnym wnętrzem bez stresu i komplikacji.</p>
                  <p><br></p>`,
    link: "oferta/remont-wewnetrzny",
  },
  {
    number: "02",
    imgSrc: Elewacje,
    img1Src: Elewacje1,
    title: "Elewacja Budynku",
    pageTitle: "Elewacja Budynku Wieliczka",
    imgAlt: "Elewacja Budynku Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faBrush} />,
    description: ` <p style="text-align: justify">Zadbana i estetyczna elewacja to wizyt&oacute;wka każdego budynku. W Remontbezpartaczy.pl specjalizujemy się w kompleksowych usługach związanych z remontem i renowacją elewacji. Nasze doświadczenie oraz zaangażowanie w każdym projekcie gwarantują, że Tw&oacute;j budynek będzie prezentował się nienagannie, a jego elewacja będzie trwała i odporna na warunki atmosferyczne.</p>
                   <p><br></p><br>
                   <h3 style="font-size: 1.5rem"><strong>Nasze Usługi Elewacyjne Obejmują: </strong><br><br></h3>
                   <p><br></p>
                   <p><strong>&bull; Oczyszczanie i renowacja elewacji:&nbsp; <br><br></strong> Usuwanie zabrudzeń, mch&oacute;w i porost&oacute;w oraz naprawa uszkodzeń, aby przywr&oacute;cić elewacji pierwotny wygląd.</p>
                   <p><br></p>
                   <p><strong>&bull; Malowanie i tynkowanie: <br><br></strong>Profesjonalne malowanie elewacji oraz nakładanie r&oacute;żnorodnych tynk&oacute;w dekoracyjnych, kt&oacute;re nadadzą budynkowi nowoczesny i elegancki wygląd.</p>
                   <p><br></p>
                   <p><strong>&bull; Docieplenia budynk&oacute;w: <br><br></strong> Montaż system&oacute;w ociepleń, kt&oacute;re zwiększą efektywność energetyczną budynku, zapewniając komfort cieplny i obniżając koszty ogrzewania.</p>
                   <p><br></p>
                   <p><br></p>
                   <h3 style="font-size: 1.5rem"><strong>Dlaczego Warto Wybrać Remontbezpartaczy.pl?</strong></h3>
                   <p><br><br></p>
                   <p><strong>&bull; Profesjonalne podejście: </strong><br><br> Każdy projekt traktujemy indywidualnie, dostosowując nasze usługi do specyfiki i potrzeb klienta.</p>
                   <p><br></p>
                   <p><strong>&bull; Doświadczenie i wiedza: </strong><br><br> Zrzeszamy wykwalifikowanych specjalist&oacute;w z wieloletnim doświadczeniem w pracy przy elewacjach budynk&oacute;w.</p>
                   <p><br></p>
                   <p><strong>&bull; Jakość i trwałość: <br><br></strong> Używamy tylko wysokiej jakości materiał&oacute;w, kt&oacute;re gwarantują długotrwałą ochronę i estetykę elewacji.</p>
                   <p><br></p>
                   <p><br></p>
                   <p>Zaufaj ekspertom od elewacji i ciesz się pięknym oraz trwałym wykończeniem swojego budynku.&nbsp;</p>
                   <p><br></p>`,
    link: "oferta/elewacja-budynku",
  },
  {
    number: "03",
    imgSrc: KostkaBrukowa,
    img1Src: KostkaBrukowa1,
    title: "Kostka Brukowa",
    pageTitle:
      "Kostka Brukowa Wieliczka | Układanie Kostki Brukowej w Wieliczce",
    imgAlt: "Kostka Brukowa Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faTrowelBricks} />,
    description: ` <p  style="text-align: justify">Estetycznie ułożona kostka brukowa to nie tylko funkcjonalne, ale i eleganckie wykończenie każdej posesji. W Remontbezpartaczy.pl specjalizujemy się w kompleksowych usługach związanych z układaniem kostki brukowej, zapewniając solidne i trwałe efekty, kt&oacute;re przetrwają lata. Niezależnie od tego, czy planujesz nową nawierzchnię podjazdu, ścieżki ogrodowej, czy tarasu, nasz zesp&oacute;ł doświadczonych fachowc&oacute;w sprosta każdemu wyzwaniu.</p>
                  <p><br></p><br>
                   <h3 style="font-size: 1.5rem"><strong>Nasze Usługi Brukarskie Obejmują: </strong><br><br></h3>
                  <p><br></p>
                  <p><strong>&bull; Projektowanie i doradztwo: </strong><br><br> Pomoc w doborze odpowiednich materiał&oacute;w, wzor&oacute;w i kolor&oacute;w kostki brukowej, dostosowanych do stylu Twojej posesji i osobistych preferencji.</p>
                  <p><br></p>
                  <p><strong>&bull; Przygotowanie podłoża: </strong><br><br> Profesjonalne przygotowanie terenu, w tym wykopy, niwelacja, oraz zapewnienie odpowiedniego drenażu i stabilizacji gruntu.</p>
                  <p><br></p>
                  <p><strong>&bull; Układanie kostki brukowej: <br></strong>Precyzyjne układanie kostki według wybranego wzoru, zapewniające estetyczny wygląd i trwałość nawierzchni.</p>
                  <p><br></p>
                  <p><strong>&bull; Obrzeża i krawężniki: <br></strong>Montaż obrzeży, krawężnik&oacute;w i innych element&oacute;w wykończeniowych, kt&oacute;re podkreślają elegancję i funkcjonalność układanej kostki.</p>
                  <p><br></p>
                  <p><br></p>
                   <h3 style="font-size: 1.5rem"><strong>Dlaczego Warto Wybrać Remontbezpartaczy.pl?</strong></h3>
                  <p><br></p>
                  <p><strong>&bull; Indywidualne podejście: </strong><br><br> Każdy projekt realizujemy z pełnym zaangażowaniem, dostosowując nasze usługi do Twoich oczekiwań i specyfiki terenu.</p>
                  <p><br></p>
                  <p><strong>&bull; Doświadczenie i profesjonalizm:<br><br> </strong>Zrzeszamy wykwalifikowanych fachowc&oacute;w z wieloletnim doświadczeniem w branży brukarskiej.</p>
                  <p><br></p>
                  <p>Zaufaj ekspertom od kostki brukowej i ciesz się pięknie oraz solidnie wykończonymi powierzchniami wok&oacute;ł Twojej posesji.&nbsp;</p>`,
    link: "oferta/kostka-brukowa",
  },
  {
    number: "04",
    imgSrc: Ogrod,
    img1Src: Ogrod1,
    title: "Ogród",
    pageTitle: "Ogród Wieliczka",
    imgAlt: "Ogród Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faSunPlantWilt} />,
    description: ` <p  style="text-align: justify">Piękny ogr&oacute;d to oaza spokoju, miejsce wypoczynku i dumy każdego właściciela domu. W Remontbezpartaczy.pl oferujemy kompleksowe usługi ogrodnicze, kt&oacute;re pozwolą Ci cieszyć się estetycznie urządzoną i funkcjonalną przestrzenią wok&oacute;ł Twojej posesji. Nasz zesp&oacute;ł doświadczonych specjalist&oacute;w zadba o każdy detal, tworząc ogr&oacute;d, kt&oacute;ry spełni Twoje marzenia i potrzeby.</p>
                  <p><br></p>
                  <h3 style="font-size: 1.5rem"><br><strong>Nasze Usługi Ogrodnicze Obejmują: </strong><br><br></h3>
                  <p><br></p>
                  <p><strong>&bull; Projektowanie ogrod&oacute;w: <br><br></strong>Tworzenie unikalnych projekt&oacute;w dostosowanych do indywidualnych oczekiwań i specyfiki terenu. Zadbamy o każdy element &ndash; od wyboru roślin po układ ścieżek i element&oacute;w dekoracyjnych.</p>
                  <p><strong><br></strong></p>
                  <p><strong>&bull; Zakładanie trawnik&oacute;w: </strong><br><br> Przygotowanie podłoża i zakładanie trawnik&oacute;w z siewu lub trawy z rolki, aby uzyskać gęstą i zieloną murawę.</p>
                  <p><br></p>
                  <p><strong>&bull; Nasadzenia roślin: </strong><br><br> Dob&oacute;r i sadzenie drzew, krzew&oacute;w, kwiat&oacute;w oraz roślin ozdobnych, kt&oacute;re będą harmonijnie komponować się w Twoim ogrodzie.</p>
                  <p><br></p>
                  <p><strong>&bull; Instalacja system&oacute;w nawadniających: </strong><br><br> Montaż automatycznych system&oacute;w nawadniających, kt&oacute;re zapewnią optymalne nawodnienie roślin, oszczędzając Tw&oacute;j czas i wodę.</p>
                  <p><br></p>
                  <p><strong>&bull; Budowa element&oacute;w małej architektury: </strong><br><br> Tworzenie ścieżek, altan, pergoli, oczek wodnych, basen&oacute;w, fontann i innych element&oacute;w, kt&oacute;re dodadzą uroku Twojemu ogrodowi.</p>
                  <p><br></p>
                  <p><br></p>
                   <h3 style="font-size: 1.5rem"><strong>Dlaczego Warto Wybrać Remontbezpartaczy.pl?</strong></h3><br>
                  <p><br></p>
                  <p><strong>&bull; Kreatywne podejście:<br><br></strong> Każdy ogr&oacute;d jest dla nas wyjątkowym projektem, do kt&oacute;rego podchodzimy z pasją i zaangażowaniem.</p>
                  <p><br></p>
                  <p><strong>&bull; Doświadczenie i fachowość:<br><br> </strong>Zrzeszamy wykwalifikowanych ogrodnik&oacute;w z wieloletnim doświadczeniem.</p>
                  <p><br><br></p>
                  <p>Stw&oacute;rz z nami sw&oacute;j wymarzony ogr&oacute;d i ciesz się piękną, zieloną przestrzenią.&nbsp;</p><br>`,
    link: "oferta/ogrod",
  },
  {
    number: "05",
    imgSrc: KamiennyDywan,
    img1Src: KamiennyDywan,
    title: "Kamienny Dywan",
    pageTitle: "Kamienny Dywan Wieliczka",
    imgAlt: "Kamienny Dywan Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faRoad} />,
    description: ` <p  style="text-align: justify">Kamienny dywan to nowoczesne i eleganckie rozwiązanie na wykończenie podł&oacute;g zar&oacute;wno na zewnątrz, jak i wewnątrz budynk&oacute;w. W Remontbezpartaczy.pl oferujemy profesjonalne usługi związane z układaniem kamiennego dywanu, kt&oacute;ry łączy estetykę z wyjątkową trwałością i funkcjonalnością. Nasz zesp&oacute;ł doświadczonych fachowc&oacute;w zapewni realizację, kt&oacute;ra spełni Twoje oczekiwania i nada wyjątkowy charakter każdemu miejscu.</p>
                  <p><br></p><br>
                  <h3 style="font-size: 1.5rem"><strong>Nasze Usługi w Zakresie Kamiennego Dywanu Obejmują: </strong><br><br></h3>
                  <p><br></p>
                  <p><strong>&bull; Przygotowanie podłoża: </strong><br><br> Profesjonalne przygotowanie podłoża, w tym oczyszczanie, wyr&oacute;wnywanie i zabezpieczanie powierzchni, co zapewnia trwałość i stabilność kamiennego dywanu.</p>
                  <p><br></p>
                  <p><strong>&bull; Układanie kamiennego dywanu: <br><br></strong>Precyzyjne układanie kamieni naturalnych lub kwarcowych z wykorzystaniem specjalistycznych żywic, kt&oacute;re gwarantują trwałość i odporność na warunki atmosferyczne.</p>
                  <p><br></p>
                  <p><br></p>
                  <h3 style="font-size: 1.5rem"><strong>Zastosowania Kamiennego Dywanu: </strong><br><br></h3>
                  <p><br></p>
                  <p><strong>&bull; Tarasy i balkony: </strong><br><br> Eleganckie i trwałe wykończenie, odporne na warunki atmosferyczne.</p>
                  <p><br></p>
                  <p><strong>&bull; Ścieżki ogrodowe: <br><br></strong>Estetyczne i funkcjonalne rozwiązanie dla Twojego ogrodu.</p>
                  <p><br></p>
                  <p><strong>&bull; Podjazdy i parkingi: </strong><br><br> Wytrzymała nawierzchnia, kt&oacute;ra sprosta obciążeniom i intensywnemu użytkowaniu.</p>
                  <p><br></p>
                  <p><strong>&bull; Wnętrza budynk&oacute;w: <br><br></strong>Nowoczesne i eleganckie podłogi w salonach, holach, łazienkach i kuchniach.</p>
                  <p><br></p>
                  <p><strong>&bull; Baseny i strefy wellness: </strong><br><br> Antypoślizgowa powierzchnia, idealna do miejsc narażonych na wilgoć.</p>
                  <p><br></p>
                  <p><br></p>
                   <h3 style="font-size: 1.5rem"><strong>Dlaczego Warto Wybrać Remontbezpartaczy.pl?</strong></h3>
                  <p><br><br></p>
                  <p><strong>&bull; Profesjonalizm i doświadczenie: <br><br></strong>Zrzeszamy wykwalifikowanych specjalist&oacute;w z wieloletnim doświadczeniem w układaniu powierzchni z kamiennego dywanu.</p>
                  <p><br></p>
                  <p><strong>&bull; Indywidualne podejście: <br><br></strong>Każdy projekt realizujemy z pełnym zaangażowaniem, dostosowując nasze usługi do Twoich potrzeb i oczekiwań.</p>
                  <p><br><br></p>
                  <p>Zaufaj ekspertom od kamiennego dywanu i ciesz się piękną, trwałą i funkcjonalną nawierzchnią w Twoim domu i ogrodzie.&nbsp;</p><br>`,
    link: "oferta/kamienny-dywan",
  },
  {
    number: "06",
    imgSrc: Ogrodzenia,
    img1Src: Ogrodzenia,
    title: "Ogrodzenia",
    pageTitle: "Ogrodzenia Wieliczka | Płoty Wieliczka",
    imgAlt: "Ogrodzenia Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faXmarksLines} />,
    description: ` <p  style="text-align: justify">Solidne i estetyczne ogrodzenie to nie tylko element zabezpieczający Twoją posesję, ale r&oacute;wnież jej wizyt&oacute;wka. W Remontbezpartaczy.pl oferujemy kompleksowe usługi związane z projektowaniem, montażem oraz konserwacją r&oacute;żnorodnych typ&oacute;w ogrodzeń. Niezależnie od tego, czy marzysz o nowoczesnym ogrodzeniu metalowym, czy funkcjonalnym ogrodzeniu betonowym, nasz zesp&oacute;ł specjalist&oacute;w zadba o każdy detal, aby spełnić Twoje oczekiwania.</p>
                  <p><br></p><br>
                   <h3 style="font-size: 1.5rem"><strong>Nasze Usługi Ogrodzeniowe Obejmują: </strong><br><br></h3>
                  <p><br></p>
                  <p><strong>&bull; Montaż ogrodzeń: <br><br></strong>Profesjonalne montowanie ogrodzeń z r&oacute;żnych materiał&oacute;w, w tym metalu, betonu i paneli z drutu.</p>
                  <p><br></p>
                  <p><strong>&bull; Bramy i furtki: <br><br></strong>Instalacja bram wjazdowych, furtek oraz automatycznych system&oacute;w otwierania, kt&oacute;re zapewniają wygodę i bezpieczeństwo.</p>
                  <p><strong><br></strong></p>
                  <p><strong>&bull; Konserwacja i naprawa:<br><br> </strong>Regularne przeglądy, konserwacja i naprawa uszkodzonych element&oacute;w ogrodzenia, aby zachować jego funkcjonalność i estetykę na długie lata.</p>
                  <p><br></p>
                  <p><strong>&bull; Elementy dekoracyjne:<br><br> </strong>Montaż dodatkowych element&oacute;w, takich jak lampy ogrodowe, trejaże, kratki i inne dekoracje, kt&oacute;re nadadzą ogrodzeniu unikalny charakter.</p>
                  <p><br></p>
                  <p><br></p>
                   <h3 style="font-size: 1.5rem"><strong>Rodzaje Ogrodzeń, Kt&oacute;re Oferujemy: </strong><br><br></h3>
                  <p><br></p>
                  <p><strong>&bull; Ogrodzenia metalowe: <br><br></strong>Nowoczesne i trwałe, doskonale sprawdzające się w miejskich i industrialnych aranżacjach.</p>
                  <p><strong><br></strong></p>
                  <p><strong>&bull; Ogrodzenia betonowe: <br><br></strong>Solidne i odporne na warunki atmosferyczne, zapewniające pełne bezpieczeństwo.</p>
                  <p><br></p>
                  <p><strong>&bull; Ogrodzenia panelowe:<br><br> </strong>Szybkie w montażu, funkcjonalne i estetyczne rozwiązanie dla r&oacute;żnorodnych posesji.</p>
                  <p><br></p>
                  <p><br></p>
                   <h3 style="font-size: 1.5rem"><strong>Dlaczego Warto Wybrać Remontbezpartaczy.pl?</strong></h3><br>
                  <p><br></p>
                  <p><strong>&bull; Indywidualne podejście: </strong><br><br> Każdy projekt traktujemy z pełnym zaangażowaniem, dostosowując nasze usługi do Twoich oczekiwań i specyfiki terenu.</p>
                  <p><br></p>
                  <p><strong>&bull; Doświadczenie i profesjonalizm: <br></strong>Zrzeszamy wykwalifikowanych fachowc&oacute;w z wieloletnim doświadczeniem w branży ogrodzeniowej.</p>
                  <p><br></p>
                  <p>Zaufaj ekspertom od ogrodzeń i ciesz się pięknie oraz solidnie wykończoną posesją.</p><br>`,
    link: "oferta/ogrodzenia",
  },
  {
    number: "07",
    imgSrc: Elektryka,
    img1Src: Elektryka,
    title: "Elektryka",
    pageTitle: "Elektryka Wieliczka | Instalacje Elektryczne Wieliczka",
    imgAlt: "Elektryka Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faLightbulb} />,
    description: ` <p  style="text-align: justify">Bezpieczna i funkcjonalna instalacja elektryczna to podstawa każdego budynku. W Remontbezpartaczy.pl oferujemy kompleksowe usługi elektryczne, dostosowane do indywidualnych potrzeb naszych klient&oacute;w. Niezależnie od tego, czy potrzebujesz nowej instalacji elektrycznej, modernizacji starej, czy drobnych napraw, nasz zesp&oacute;ł doświadczonych elektryk&oacute;w zapewni Ci profesjonalne i rzetelne wykonanie.</p>
                   <p><br></p>
                   <p><br></p>
                   <h3 style="font-size: 1.5rem"><strong>Nasze Usługi Elektryczne Obejmują: </strong><br><br></h3>
                   <p><br></p>
                   <p><strong>&bull; Projektowanie instalacji elektrycznych: <br><br></strong>Tworzenie indywidualnych projekt&oacute;w instalacji elektrycznych, uwzględniających specyfikę budynku i potrzeby użytkownik&oacute;w.</p>
                   <p><br></p>
                   <p><strong>&bull; Montaż instalacji elektrycznych: </strong><br><br> Profesjonalne układanie kabli, montaż gniazdek, wyłącznik&oacute;w, oświetlenia i innych element&oacute;w instalacji.</p>
                   <p><br></p>
                   <p><strong>&bull; Modernizacja i rozbudowa: </strong><br><br> Unowocześnienie i rozbudowa istniejących instalacji elektrycznych, aby sprostały wymaganiom wsp&oacute;łczesnych urządzeń i norm bezpieczeństwa.</p>
                   <p><br></p>
                   <p><strong>&bull; Usuwanie awarii: </strong><br><br> Szybka i skuteczna diagnoza oraz naprawa usterek elektrycznych, zapewniająca ciągłość dostaw prądu i bezpieczeństwo użytkowania.</p>
                   <p><strong><br></strong></p>
                   <p><strong>&bull; Instalacje oświetleniowe: <br><br></strong>Montaż i serwis r&oacute;żnorodnych system&oacute;w oświetleniowych, w tym oświetlenia wewnętrznego, zewnętrznego, dekoracyjnego i awaryjnego.</p>
                   <p><br></p>
                   <p><strong>&bull; Inteligentne systemy: <br><br></strong>Instalacja i konfiguracja inteligentnych system&oacute;w zarządzania energią, automatyki domowej, alarm&oacute;w i monitoringu.</p>
                   <p><br></p>
                   <p><strong>&bull; Przeglądy i pomiary: <br><br></strong>Regularne przeglądy instalacji elektrycznych oraz wykonywanie pomiar&oacute;w, kt&oacute;re zapewniają bezpieczeństwo i zgodność z normami.</p>
                   <p><br></p>
                   <p><br></p>
                   <h3 style="font-size: 1.5rem"><strong>Dlaczego Warto Wybrać Remontbezpartaczy.pl?</strong></h3><br>
                   <p><br></p>
                   <p><strong>&bull; Profesjonalizm i doświadczenie: </strong><br><br> Zrzeszamy wykwalifikowanych elektryk&oacute;w z wieloletnim doświadczeniem.</p>
                   <p><br></p>
                   <p><strong>&bull; Indywidualne podejście: </strong><br><br> Każdy projekt realizujemy z pełnym zaangażowaniem, dostosowując nasze usługi do Twoich potrzeb i oczekiwań.</p>
                   <p><br></p>
                   <p><br></p>
                   <p>Zaufaj ekspertom od elektryki i ciesz się bezpieczną oraz funkcjonalną instalacją w swoim domu lub firmie.</p>
                   <p><br></p>`,
    link: "oferta/elektryka",
  },
  {
    number: "08",
    imgSrc: IntaligentnyDom,
    img1Src: IntaligentnyDom1,
    title: "Inteligentny Dom",
    pageTitle: "Inteligentny Dom Wieliczka | Smart Home",
    imgAlt: "Inteligentny Dom Wieliczka remontbezpartaczy.pl",
    icon: <FontAwesomeIcon icon={faHouseSignal} />,
    description: ` <p  style="text-align: justify">Tw&oacute;j dom może być bardziej inteligentny, komfortowy i bezpieczny dzięki nowoczesnym technologiom. W Remontbezpartaczy.pl oferujemy kompleksowe usługi związane z projektowaniem i instalacją system&oacute;w inteligentnego domu. Niezależnie od tego, czy chcesz zautomatyzować oświetlenie, zarządzać ogrzewaniem, czy monitorować swoje bezpieczeństwo, nasz zesp&oacute;ł specjalist&oacute;w pomoże Ci w pełni wykorzystać potencjał nowoczesnych rozwiązań.</p>
                   <p><br></p>
                   <p><br></p>
                 <h3 style="font-size: 1.5rem"><br /><strong>Nasze Usługi w Zakresie Inteligentnego Domu Obejmują: </strong><br><br></h3>
                  <p ><br></p>
                  <p ><strong>&bull; Projektowanie system&oacute;w inteligentnego domu:<br><br> </strong>Tworzenie spersonalizowanych projekt&oacute;w, kt&oacute;re uwzględniają indywidualne potrzeby i preferencje użytkownik&oacute;w oraz specyfikę budynku.</p>
                  <p ><br></p>
                  <p ><strong>&bull; Instalacja automatyki domowej: <br><br> </strong>Montaż i konfiguracja urządzeń oraz system&oacute;w zarządzających oświetleniem, ogrzewaniem, klimatyzacją, roletami, bramami i innymi elementami domu.</p>
                  <p ><br></p>
                  <p ><strong>&bull; Zarządzanie energią: <br><br> </strong>Instalacja system&oacute;w monitorujących zużycie energii oraz inteligentnych rozwiązań, kt&oacute;re pomagają obniżyć koszty eksploatacji domu.</p>
                  <p><br></p>
                  <p ><strong>&bull; Systemy bezpieczeństwa:<br><br> </strong> Montaż inteligentnych alarm&oacute;w, kamer monitoringu, czujnik&oacute;w dymu i gazu oraz innych urządzeń zapewniających bezpieczeństwo mieszkańc&oacute;w.</p>
                  <p ><br></p>
                  <p ><strong>&bull; Sterowanie głosowe:<br><br>  </strong>Integracja system&oacute;w inteligentnego domu z asystentami głosowymi, takimi jak Amazon Alexa, Google Assistant czy Apple Siri, umożliwiająca zarządzanie domem za pomocą komend głosowych.</p>
                  <p ><br></p>
                  <p ><strong>&bull; Zdalne zarządzanie: <br><br> </strong>Konfiguracja aplikacji mobilnych, kt&oacute;re pozwalają na zdalne sterowanie i monitorowanie wszystkich system&oacute;w inteligentnego domu z dowolnego miejsca na świecie.</p>
                  <p ><strong><br></strong></p>
                  <p ><strong>&bull; Integracja system&oacute;w audio-wideo: <br><br> </strong>Instalacja i konfiguracja system&oacute;w multimedialnych, kt&oacute;re pozwalają na centralne zarządzanie rozrywką w domu.</p>
                  <p ><br></p>
                  <p ><br></p>
                   <h3 style="font-size: 1.5rem"><strong>Dlaczego Warto Wybrać Remontbezpartaczy.pl?</strong></h3><br>
                  <p ><br></p>
                   <p><strong>&bull; Profesjonalizm i doświadczenie:&nbsp;</strong><br><br> Zrzeszamy wykwalifikowanych specjalist&oacute;w z wieloletnim doświadczeniem.</p>
                   <p>&bull; Indywidualne podejście: Każdy projekt realizujemy z pełnym zaangażowaniem, dostosowując nasze usługi do Twoich potrzeb i oczekiwań.</p>
                   <p><br></p>
                   <p><strong>&bull; Bezpieczeństwo i wygoda:<br><br> </strong> Dbamy o to, aby wszystkie instalowane systemy były bezpieczne, łatwe w obsłudze i zwiększały komfort życia.</p>
                   <p><br></p>
                   <p></p>
                   <p>Zaufaj ekspertom od inteligentnych system&oacute;w i ciesz się nowoczesnym, komfortowym oraz bezpiecznym domem.&nbsp;</p>`,
    link: "oferta/inteligentny-dom",
  },
];

export const videoSectionData = {
  title:
    "Puszka po piwie? Rozrzucone wypalone papierosy? Zapomnij o takich obrazkach. " +
    "Porządek i wysoka kultura osobista – tak wyobrażamy sobie pracę w Twoim Domu i wokół niego.",
  btnText: "Zobacz teraz",
  btnIcon: <FaPlay />,
};

export const testimonialData = [
  {
    quoteImg: QuoteImg,
    message:
      "Bez stresu, bez tradycyjnych problemów z wykonawcami, dla Nas najważniejsze jest to, żebyś się odprężył i przestał o remoncie myśleć.",
    name: "RemontBezPartaczy",
    occupation: "Zespół",
    scroll: ">>>",
  },
  {
    quoteImg: QuoteImg,
    message: "Zapewniam Ci, że z Nami dobry efekt końcowy masz gwarantowany",
    name: "RemontBezPartaczy",
    occupation: "Zespół",
    scroll: "<<<",
  },
];

export const contactData = {
  formTitle: "Napisz do nas: ",
  info: [
    {
      title: "Biuro Wieliczka",
      subtitle:
        "Napisz co potrzebujesz zrobić, a kontakt z Naszej strony otrzymasz w ciągu 24h.",
      subtitle1: "Właścicielem marki Remontbezpartaczy.pl jest firma DSH",
      address: {
        icon: <FaMapMarkerAlt />,
        name: "Kokotów 180, Wieliczka, małopolskie",
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: "+48 784 837 835",
      },
      email: {
        icon: <FaEnvelope />,
        address: "remontbezpartaczy@gmail.com",
      },
      nip: {
        icon: <FaElementor />,
        address: "NIP: 9442281130",
      },
      link: "Sprawdź jak dojechać",
    },
  ],
  form: {
    name: "Imię i nazwisko",
    email: "Adres email",
    phone: "Twój numer telefonu",
    message: "Twoja wiadomość",
    btnText: "Wyślij",
  },
};

export const footerData = {
  about: {
    title: "Odezwij się do nas!",
    subtitle: "Zapewniam Ci, że z Nami dobry efekt końcowy masz gwarantowany!",
    address: {
      icon: <FaMapMarkerAlt />,
      name: "Kokotów 180, Wieliczka, małopolskie",
    },
    phone: {
      icon: <FaPhoneAlt />,
      number: "+48 784 837 835",
    },
    email: {
      icon: <FaEnvelope />,
      address: "remontbezpartaczy@gmail.com",
    },
  },
  links: {
    title: "Przydatne linki",
    items: [
      { href: "/oferta", name: "Oferta" },
      { href: "/galeria", name: "Galeria" },
      { href: "/poradnik", name: "Poradnik" },
      { href: "/faqs", name: "FAQs" },
      { href: "/kontakt", name: "Kontakt" },
    ],
  },
  program: {
    title: "Kiedy pracujemy?",
    items: [
      { name: "Pon - Pt / 8:00 - 16:00" },
      { name: "Sob /  10:00 - 16:00" },
      { name: "Niedz / odpoczywamy" },
    ],
  },
  newsletter: {
    title: "Potrzebujesz pomocy?",
    subtitle: "Zostaw nam swój telefon a na pewno oddzwonimy:",
    form: {
      placeholder: "Twój numer telefonu",
      icon: <FiSend />,
    },
  },
};

export const questionsAnswers = [
  {
    question: "W jakiej lokalizacji wykonywane są usługi?",
    answer:
      "Powiat Wielicki, Powiat Bocheński, Powiat Krakowski i Powiat Myślenicki",
  },
  {
    question: "Kiedy przyjdzie wycena?",
    answer:
      "Pierwszy kontakt z Naszej strony jest do 24h\n" +
      "Wycena jest wykonana zazwyczaj do kilku dni po pierwszym kontakcie z Naszej strony.",
  },
  {
    question: "Jak długi jest okres gwarancji na usługę?",
    answer: `Okres gwarancji na wykonaną przez Nas usługę to 1 rok kalendarzowy od dnia
zakończenia prac.`,
  },
  {
    question: "Czy oględziny i wycena są płatne?",
    answer: `Wycena oraz wcześniejsze oględziny są całkowicie darmowe`,
  },
  {
    question: "Czy można skorzystać z kilku usług firmy jednocześnie",
    answer: `Posiadamy osobne ekipy w każdej z dziedzin wymienionych na stronie i nie ma
problemu aby prace w różnych obszarach były prowadzone jednocześnie`,
  },
];
