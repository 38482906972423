import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/varients";
import { questionsAnswers } from "../../data/data";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContactForm from "../contact/ContactForm";
import { Helmet } from "react-helmet";

const FAQs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Firma Remontowa Wieliczka | Usługi Remonotwe Wieliczka |FAQs`;
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Firma Remontowa Wieliczka | Usługi Remonotwe Wieliczka |FAQs`
        </title>
      </Helmet>
      <section className="section relative px-7 md:px-10 lg:mt-0 mt-24">
        <div className="container mx-auto  lg:justify-start justify-center">
          <motion.h2
            variants={fadeIn("up")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.4 }}
            className="h2 max-w-[370px] lg:mb-20 flex xl:justify-start justify-center"
          >
            FAQs
          </motion.h2>
          <div className={" justify-center flex w-full"}>
            <div className={"relative w-[80%]"}>
              {questionsAnswers.map((item, i) => (
                <div className={"mb-6"}>
                  <Accordion>
                    <AccordionSummary
                      className={"bg-amber-100"}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      {item.question}
                    </AccordionSummary>
                    <AccordionDetails>{item.answer}</AccordionDetails>
                  </Accordion>
                </div>
              ))}
            </div>
          </div>

          <div className={"w-[100%] mt-10 flex justify-center"}>
            <div className={"lg:w-[50%]"}>
              <motion.h3
                variants={fadeIn("up")}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.4 }}
                className="h3 lg:text-3xl text-lg lg:mt-20 lg:mb-20 mb-10 flex xl:text-start text-center"
              >
                Brakuje odpowiedzi na Twoje pytanie? Napisz do nas!
              </motion.h3>
              <ContactForm></ContactForm>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default FAQs;
