import React, { useEffect } from "react";
import { fadeIn } from "../../utils/varients";
import { motion } from "framer-motion";
import Card from "../common/Card";
import { blogData } from "../../data/blogData";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { IoMdArrowForward } from "react-icons/io";

const BlogList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Usługi Remontowe i Wykończeniowe Wieliczka Kraków RemontBezPartaczy.pl
          Poradnik
        </title>
      </Helmet>
      <div className="section lg:mt-0 mt-24">
        <div className="container mx-auto">
          <motion.h2
            variants={fadeIn("up")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.6 }}
            className="h2 max-w-[370px] lg:mb-20 flex lg:justify-start justify-center"
          >
            Poradnik
          </motion.h2>
        </div>
        <motion.div
          variants={fadeIn("up")}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: "some" }}
          className="mb-8 lg:mb-20  flex flex-row flex-wrap justify-center pt-0 xl:gap-8 gap-4 opacity-100"
        >
          {blogData.map((service, index) => (
            <Card
              key={index}
              imgSrc={service.imgSrc}
              imgAlt={service.imgAlt}
              title={service.title}
              link={service.link}
              description={service.description}
            ></Card>
          ))}
        </motion.div>{" "}
        <Link
          to={`${process.env.REACT_APP_API_BASE_URL}/oferta`}
          className={"w-full flex justify-center"}
        >
          <button className="btn btn-lg btn-dark" aria-label="Zobacz więcej">
            Zobacz ofertę
            <div className="text-xl">
              <IoMdArrowForward />
            </div>
          </button>
        </Link>
      </div>
    </>
  );
};
export default BlogList;
