export const blogDescriptions = [
  {
    description: `<p style="text-align: justify;"><span style="font-size:12pt;">Ogrzewanie podłogowe to nowoczesny system grzewczy, kt&oacute;ry zapewnia komfort cieplny i r&oacute;wnomierne rozprowadzanie ciepła w pomieszczeniach. Poniżej znajdziesz szczeg&oacute;łowy poradnik, jak samodzielnie wykonać ogrzewanie podłogowe.</span><br><br><br></p>
<p><strong><span style="font-size: 20px;">Krok 1: Planowanie i Przygotowanie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
<ol>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Wyb&oacute;r systemu ogrzewania podłogowego</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p><strong>Elektryczne ogrzewanie podłogowe</strong>: szybsze w montażu, idealne do małych pomieszczeń.</p>
            </li>
            <li style="list-style-type:disc;font-size:12pt;">
                <p><strong>Wodne ogrzewanie podłogowe</strong>: bardziej efektywne w dużych powierzchniach, ale wymaga więcej pracy przy instalacji.</p>
            </li>
        </ul>
    </li>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Obliczenie zapotrzebowania cieplnego</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Określ, ile ciepła potrzebujesz, biorąc pod uwagę izolację budynku, wielkość pomieszczeń i lokalizację.</p>
            </li>
        </ul>
    </li>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Wyb&oacute;r materiał&oacute;w i narzędzi</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Rury lub maty grzewcze (w zależności od systemu)</p>
            </li>
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Izolacja termiczna</p>
            </li>
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Folia paroizolacyjna</p>
            </li>
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Rozdzielacz (dla systemu wodnego)</p>
            </li>
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Sterowniki i termostaty</p>
            </li>
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Narzędzia: nożyce, wiertarka, poziomica, klucze<br><br><br></p>
            </li>
        </ul>
    </li>
</ol>
<p><strong><span style="font-size: 20px;">Krok 2: Przygotowanie Podłoża</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
<ol>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Czyszczenie i wyr&oacute;wnanie podłoża</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Usuń wszelkie zanieczyszczenia i nier&oacute;wności, aby uzyskać gładką powierzchnię.</p>
            </li>
        </ul>
    </li>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Izolacja termiczna</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Rozł&oacute;ż warstwę izolacji termicznej, aby zapobiec stratom ciepła do podłoża.</p>
            </li>
        </ul>
    </li>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Folia paroizolacyjna</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Nał&oacute;ż folię paroizolacyjną, aby chronić izolację termiczną przed wilgocią.<br><br><br></p>
            </li>
        </ul>
    </li>
</ol>
<p><strong><span style="font-size: 20px;">Krok 3: Montaż Systemu Ogrzewania Podłogowego</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
<ol>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Układanie rur (dla systemu wodnego)</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Rozł&oacute;ż rury zgodnie z wybranym schematem (np. spirala lub meander).</p>
            </li>
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Przymocuj rury do podłoża za pomocą klamr lub specjalnych mat.</p>
            </li>
        </ul>
    </li>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Podłączenie rozdzielacza (dla systemu wodnego)</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Podłącz rury do rozdzielacza, kt&oacute;ry pozwala na kontrolowanie przepływu wody w poszczeg&oacute;lnych obiegach.</p>
            </li>
        </ul>
    </li>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Układanie mat grzewczych (dla systemu elektrycznego)</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Rozł&oacute;ż maty grzewcze r&oacute;wnomiernie na podłożu, upewniając się, że są dobrze przymocowane.</p>
            </li>
        </ul>
    </li>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Podłączenie przewod&oacute;w i sterownik&oacute;w (dla systemu elektrycznego)</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Podłącz przewody grzewcze do sterownik&oacute;w i termostat&oacute;w, kt&oacute;re pozwolą na regulację temperatury.<br><br><br></p>
            </li>
        </ul>
    </li>
</ol>
<p><strong><span style="font-size: 20px;">Krok 4: Testowanie Systemu</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
<ol>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Sprawdzenie szczelności (dla systemu wodnego)</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Przeprowadź test ciśnieniowy, aby upewnić się, że rury są szczelne.</p>
            </li>
        </ul>
    </li>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Testowanie elektryki (dla systemu elektrycznego)</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Sprawdź poprawność podłączeń elektrycznych i przetestuj działanie systemu.<br><br><br></p>
            </li>
        </ul>
    </li>
</ol>
<p><strong><span style="font-size: 20px;">Krok 5: Wylewka i Wykończenie Podłogi</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
<ol>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Wylewka betonowa</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Wylej warstwę betonu lub specjalnej masy samopoziomującej na ułożone rury lub maty.</p>
            </li>
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Upewnij się, że powierzchnia jest r&oacute;wna i gładka.</p>
            </li>
        </ul>
    </li>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Wykończenie podłogi</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Po całkowitym wyschnięciu wylewki (zazwyczaj 3-4 tygodnie), możesz przystąpić do układania finalnej warstwy podłogi (np. panele, płytki, parkiet).<br><br><br></p>
            </li>
        </ul>
    </li>
</ol>
<p><strong><span style="font-size: 20px;">Krok 6: Ustawienia i Użytkowanie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
<ol>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Regulacja temperatury</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Ustaw termostaty na żądaną temperaturę.</p>
            </li>
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Pamiętaj, aby system ogrzewania podłogowego działał efektywnie, nie przekraczaj zalecanych temperatur.</p>
            </li>
        </ul>
    </li>
    <li style="list-style-type: decimal; font-size: 18px;">
        <p><strong>Konserwacja</strong>:</p>
        <ul style="font-size: initial;">
            <li style="list-style-type:disc;font-size:12pt;">
                <p>Regularnie sprawdzaj stan systemu i przeprowadzaj konserwację, aby zapewnić jego długowieczność i efektywność.<br><br><br></p>
            </li>
        </ul>
    </li>
</ol>
<p style="text-align: justify;"><span style="font-size:12pt;">Ogrzewanie podłogowe to doskonałe rozwiązanie dla tych, kt&oacute;rzy cenią komfort i oszczędność energii. Dzięki temu poradnikowi możesz samodzielnie zainstalować system grzewczy, kt&oacute;ry będzie służył przez wiele lat.</span></p>`,
  },
  {
    description: ` <p style="text-align: justify;"><span style="font-size:12pt;">Malowanie ścian to jeden z najprostszych i najbardziej efektywnych sposob&oacute;w na odświeżenie wyglądu wnętrza. Oto krok po kroku, jak to zrobić:</span><br><br><br></p>
    <p style="text-align: justify;"><strong><span style="font-size: 20px;">Krok 1: Planowanie i Przygotowanie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ul>
        <li style="list-style-type:disc;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Wyb&oacute;r farby</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:circle;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Wybierz odpowiedni rodzaj farby do wnętrz (np. matową, p&oacute;łmatową, satynową).</span></p>
                </li>
                <li style="list-style-type:circle;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Zdecyduj o kolorze, biorąc pod uwagę oświetlenie, meble i osobiste preferencje.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Zakup materiał&oacute;w i narzędzi</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:circle;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Farba</span></p>
                </li>
                <li style="list-style-type:circle;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Podkład (jeśli konieczne)</span></p>
                </li>
                <li style="list-style-type:circle;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Pędzle i wałki</span></p>
                </li>
                <li style="list-style-type:circle;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Taca na farbę</span></p>
                </li>
                <li style="list-style-type:circle;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Taśma malarska</span></p>
                </li>
                <li style="list-style-type:circle;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Folia ochronna lub gazety</span></p>
                </li>
                <li style="list-style-type:circle;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Papier ścierny</span></p>
                </li>
                <li style="list-style-type:circle;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Kij teleskopowy (dla trudno dostępnych miejsc)</span></p>
                </li>
                <li style="list-style-type:circle;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Drabina</span></p>
                </li>
                <li style="list-style-type:circle;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Środek czyszczący do ścian (np. mydło malarskie)</span><br><br><br></p>
                </li>
            </ul>
        </li>
    </ul>
    <p style="text-align: justify;"><strong><span style="font-size: 20px;">Krok 2: Przygotowanie Pomieszczenia</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Opr&oacute;żnienie pokoju</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Usuń meble lub przesuń je na środek pokoju i przykryj folią ochronną.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Zdejmij obrazy, zasłony i wszelkie inne dekoracje.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Zabezpieczenie powierzchni</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Przyklej taśmę malarską wzdłuż krawędzi sufitu, listew przypodłogowych, okien i drzwi.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Rozł&oacute;ż folię ochronną lub gazety na podłodze, aby zabezpieczyć ją przed farbą.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Przygotowanie ścian</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Oczyść ściany z kurzu i brudu przy użyciu środka czyszczącego i gąbki.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Wypełnij wszelkie dziury i pęknięcia szpachlą, a po wyschnięciu wygładź powierzchnię papierem ściernym.</span><br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p style="text-align: justify;"><strong><span style="font-size: 20px;">Krok 3: Malowanie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Nałożenie podkładu</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Jeśli ściany mają intensywny kolor lub są nowe, zastosuj podkład, aby farba lepiej się trzymała i r&oacute;wnomiernie kryła.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Malowanie krawędzi (tzw. &bdquo;cięcie&rdquo;)</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Użyj pędzla do malowania krawędzi przy suficie, listwach przypodłogowych, oknach i drzwiach.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Nakładaj farbę r&oacute;wnomiernie, dbając o dokładność.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Malowanie ścian wałkiem</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Zanurz wałek w tacy z farbą, a następnie r&oacute;wnomiernie rozprowadź go na kratce odciekowej.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Maluj ściany w pionowych pasach, zaczynając od g&oacute;ry i schodząc w d&oacute;ł.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Staraj się nakładać farbę r&oacute;wnomiernie, unikając nadmiernego nacisku na wałek.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Druga warstwa</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Po wyschnięciu pierwszej warstwy (zgodnie z instrukcją na opakowaniu farby), nał&oacute;ż drugą warstwę farby, aby uzyskać lepsze krycie i jednolity kolor.</span><br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p style="text-align: justify;"><strong><span style="font-size: 20px;">Krok 4: Wykończenie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Usunięcie taśmy malarskiej</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Po zakończeniu malowania i lekkim podsuszeniu farby (ale zanim farba całkowicie wyschnie), delikatnie usuń taśmę malarską, aby uniknąć odprysk&oacute;w farby.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Czyszczenie narzędzi</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Umyj pędzle i wałki wodą (dla farb akrylowych) lub rozpuszczalnikiem (dla farb olejnych), aby można było je użyć ponownie.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Przywr&oacute;cenie pokoju do porządku</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p style="text-align: justify;"><span style="font-size:12pt;">Po całkowitym wyschnięciu farby, usuń folię ochronną, przestaw meble i powieś dekoracje.</span><br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p style="text-align: justify;"><strong><span style="font-size: 20px;">Krok 5: Dodatkowe Porady</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ul>
        <li style="list-style-type:disc;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Wentylacja</span></strong><span style="font-size:12pt;">: Malując, upewnij się, że pomieszczenie jest dobrze wentylowane, aby farba szybko schła i abyś unikał wdychania opar&oacute;w.</span></p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Testowanie koloru</span></strong><span style="font-size:12pt;">: Przed malowaniem całej ściany, wykonaj pr&oacute;bę koloru na małej powierzchni, aby upewnić się, że wyb&oacute;r koloru jest odpowiedni.</span></p>
        </li>
        <li style="list-style-type:disc;font-size:12pt;">
            <p style="text-align: justify;"><strong><span style="font-size:12pt;">Unikanie smug</span></strong><span style="font-size:12pt;">: Staraj się malować jedną ścianę na raz bez przerw, aby uniknąć widocznych ślad&oacute;w łączeń.</span><br><br><br></p>
        </li>
    </ul>
    <p style="text-align: justify;"><span style="font-size:12pt;">Malowanie ścian to zadanie, kt&oacute;re wymaga czasu i cierpliwości, ale dzięki dokładnemu przygotowaniu i precyzyjnemu wykonaniu można osiągnąć profesjonalny efekt. Powodzenia!</span></p>`,
  },
  {
    description: `<p style="text-align: justify;"><span style="font-size:12pt;">Dob&oacute;r odpowiedniego koloru farby do wnętrza to kluczowy element aranżacji, kt&oacute;ry może znacząco wpłynąć na atmosferę i funkcjonalność pomieszczenia. Oto szczeg&oacute;łowy poradnik, kt&oacute;ry pomoże Ci wybrać idealny kolor farby do Twojego wnętrza.</span><br><br><br></p>
    <p><strong><span style="font-size: 20px;">Krok 1: Analiza Pomieszczenia</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size:12pt;">Oświetlenie</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Naturalne światło</span></strong><span style="font-size:12pt;">: Zwr&oacute;ć uwagę na ilość naturalnego światła wpadającego do pokoju. Pokoje z dużą ilością światła dziennego mogą pozwolić sobie na ciemniejsze kolory, podczas gdy słabo oświetlone pomieszczenia lepiej wyglądają w jasnych odcieniach.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Sztuczne oświetlenie</span></strong><span style="font-size:12pt;">: Pamiętaj, że r&oacute;żne źr&oacute;dła światła (żar&oacute;wki LED, halogenowe, fluorescencyjne) mogą wpływać na postrzeganie koloru.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size:12pt;">Rozmiar pomieszczenia</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Małe pokoje</span></strong><span style="font-size:12pt;">: Jasne kolory sprawią, że pomieszczenie wyda się większe i bardziej przestronne.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Duże pokoje</span></strong><span style="font-size:12pt;">: Ciemniejsze kolory mogą dodać przytulności i intymności.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size:12pt;">Funkcja pomieszczenia</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Sypialnia</span></strong><span style="font-size:12pt;">: Wybierz kojące i relaksujące kolory, takie jak pastelowe odcienie niebieskiego, zielonego czy beżu.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Salon</span></strong><span style="font-size:12pt;">: Ciepłe i przyjazne kolory, jak odcienie ż&oacute;łtego, pomarańczowego czy czerwonego, sprzyjają towarzyskiej atmosferze.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Kuchnia</span></strong><span style="font-size:12pt;">: Jasne, czyste kolory, jak biel, szarość czy jasny niebieski, mogą dodać energii i świeżości.</span><br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 2: Inspiracje i Styl</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size:12pt;">Styl wnętrza</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Nowoczesny</span></strong><span style="font-size:12pt;">: Wybierz neutralne kolory, jak biel, szarość czy beż, z akcentami w intensywnych barwach.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Skandynawski</span></strong><span style="font-size:12pt;">: Postaw na biel, szarość i jasne odcienie drewna, kt&oacute;re dodają przestrzeni i światła.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Rustykalny</span></strong><span style="font-size:12pt;">: Ciepłe, naturalne kolory, jak odcienie brązu, zieleni i terakoty, podkreślą przytulność i naturalność.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size:12pt;">Inspiracje z natury</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Korzystaj z palet inspirowanych naturą, jak zielenie, błękity i brązy, kt&oacute;re wprowadzają spok&oacute;j i harmonię.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size:12pt;">Katalogi i magazyny wnętrzarskie</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Przeglądaj magazyny, katalogi i strony internetowe z inspiracjami, aby zobaczyć, jak r&oacute;żne kolory prezentują się w r&oacute;żnych wnętrzach.</span><br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 3: Pr&oacute;bki i Testowanie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size:12pt;">Zakup pr&oacute;bek farb</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Kup pr&oacute;bki kilku wybranych kolor&oacute;w i nał&oacute;ż je na fragmenty ścian w pokoju, aby zobaczyć, jak wyglądają w r&oacute;żnych warunkach oświetleniowych.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size:12pt;">Testowanie na dużej powierzchni</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Wykonaj test na większej powierzchni (np. kartonie), kt&oacute;ry możesz przenosić w r&oacute;żne miejsca pomieszczenia, aby ocenić, jak kolor wygląda w r&oacute;żnych częściach pokoju.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size:12pt;">Czas na decyzję</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Po nałożeniu pr&oacute;bek odczekaj kilka dni, obserwując, jak kolory zmieniają się w zależności od pory dnia i oświetlenia.</span><br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 4: Ustalanie Akcent&oacute;w i Dodatk&oacute;w</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size:12pt;">Kolory akcentowe</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Wybierz jeden lub dwa kolory akcentowe, kt&oacute;re będą uzupełniały gł&oacute;wny kolor ścian. Mogą to być intensywniejsze odcienie lub kontrastujące barwy, kt&oacute;re dodadzą wnętrzu charakteru.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size:12pt;">Dodatki i tekstylia</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Dopasuj kolory mebli, zasłon, poduszek i innych dodatk&oacute;w do wybranej palety kolor&oacute;w, aby stworzyć sp&oacute;jną aranżację.</span><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 5: Konsultacja z Fachowcem</span></strong></p>
    <p><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size:12pt;">Porada eksperta</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Jeśli masz wątpliwości co do wyboru koloru, skonsultuj się z architektem wnętrz lub specjalistą w sklepie z farbami, kt&oacute;ry może doradzić na podstawie swoich doświadczeń.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size:12pt;">Kalkulator kolor&oacute;w online</span></strong><span style="font-size:12pt;">:</span><br><br></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Skorzystaj z narzędzi online, kt&oacute;re umożliwiają wirtualne testowanie kolor&oacute;w na zdjęciach pomieszczeń, aby zobaczyć, jak będą wyglądać w rzeczywistości.</span><br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Podsumowanie</span></strong><span style="font-size: 20px;"><br></span><br></p>
    <p><span style="font-size:12pt;">Dob&oacute;r koloru farby do wnętrza to proces, kt&oacute;ry wymaga czasu, przemyślenia i testowania. Biorąc pod uwagę oświetlenie, rozmiar i funkcję pomieszczenia, a także własne preferencje stylistyczne, można stworzyć przestrzeń, kt&oacute;ra będzie nie tylko estetyczna, ale i funkcjonalna. Pamiętaj, że dobrze dobrany kolor farby może całkowicie odmienić wygląd i atmosferę Twojego domu.</span></p>`,
  },
  {
    description: `<p style="text-align: justify;"><span style="font-size:12pt;">Układanie kostki brukowej to proces, kt&oacute;ry wymaga precyzji i odpowiedniego przygotowania. Oto krok po kroku, jak prawidłowo ułożyć kostkę brukową, aby stworzyć trwałą i estetyczną nawierzchnię.</span><br><br><br></p>
        <p><strong><span style="font-size: 20px;">Krok 1: Planowanie i Przygotowanie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
        <ol>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Projektowanie</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Określ obszar, na kt&oacute;rym chcesz ułożyć kostkę brukową, i sporządź dokładny plan. Ustal wymiary i kształt powierzchni.</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Wybierz rodzaj i kolor kostki brukowej, biorąc pod uwagę styl otoczenia.</span><br><br></p>
                    </li>
                </ul>
            </li>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Zakup materiał&oacute;w i narzędzi</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Kostka brukowa</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Piasek i żwir</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Krawężniki (opcjonalnie)</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Geowł&oacute;knina</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Młotek gumowy</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Poziomica</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Szpadel</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Miarka i linia murarska</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Zagęszczarka (wibracyjna płyta)</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Szczotka</span><br><br></p>
                    </li>
                </ul>
            </li>
        </ol>
        <p><strong><span style="font-size: 20px;">Krok 2: Przygotowanie Podłoża</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
        <ol>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Wyznaczenie terenu</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Oznacz obszar za pomocą palik&oacute;w i linii murarskiej. Upewnij się, że obszar jest odpowiednio wyznaczony, z uwzględnieniem spadk&oacute;w dla odprowadzenia wody.</span><br><br></p>
                    </li>
                </ul>
            </li>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Wykopanie podłoża</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Wykop podłoże na głębokość odpowiednią dla warstw konstrukcyjnych (zwykle 25-30 cm, w zależności od obciążenia nawierzchni).</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Usuń wszelkie korzenie, kamienie i inne przeszkody.</span><br><br></p>
                    </li>
                </ul>
            </li>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Układanie geowł&oacute;kniny</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Rozł&oacute;ż geowł&oacute;kninę na dnie wykopu, aby zapobiec przerastaniu roślin i mieszaniu się warstw podbudowy z gruntem.</span><br><br></p>
                    </li>
                </ul>
            </li>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Warstwa żwiru</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Nał&oacute;ż warstwę żwiru o grubości 10-15 cm i r&oacute;wnomiernie ją rozprowadź.</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Zagęść żwir za pomocą zagęszczarki.</span><br><br></p>
                    </li>
                </ul>
            </li>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Warstwa piasku</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Na warstwę żwiru nał&oacute;ż warstwę piasku o grubości 3-5 cm.</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Wyr&oacute;wnaj piasek przy użyciu listwy niwelacyjnej, dbając o odpowiednie nachylenie dla odpływu wody.</span><br><br><br></p>
                    </li>
                </ul>
            </li>
        </ol>
        <p><strong><span style="font-size: 20px;">Krok 3: Układanie Kostki Brukowej</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
        <ol>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Układanie kostki</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Zacznij układanie kostki od jednego z rog&oacute;w, postępując wzdłuż linii wyznaczonych przez murarską linkę.</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Układaj kostkę zgodnie z wybranym wzorem, pozostawiając minimalne szczeliny między kostkami (zwykle 3-5 mm).</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Użyj młotka gumowego, aby delikatnie docisnąć każdą kostkę do warstwy piasku.</span><br><br></p>
                    </li>
                </ul>
            </li>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Krawężniki</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Jeśli stosujesz krawężniki, uł&oacute;ż je na zewnątrz obszaru kostki brukowej, aby zapewnić stabilność nawierzchni.</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Krawężniki powinny być osadzone w betonie, aby zapobiec ich przesuwaniu się.</span><br><br><br></p>
                    </li>
                </ul>
            </li>
        </ol>
        <p><strong><span style="font-size: 20px;">Krok 4: Wypełnianie Szczelin i Zagęszczanie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
        <ol>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Wypełnianie szczelin</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Po ułożeniu kostki brukowej, rozsyp suchy piasek po całej powierzchni.</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Wmień piasek w szczeliny między kostkami przy użyciu miotły. Powtarzaj ten proces, aż szczeliny będą w pełni wypełnione.</span><br><br></p>
                    </li>
                </ul>
            </li>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Zagęszczanie nawierzchni</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Zagęść całą nawierzchnię za pomocą zagęszczarki, aby kostka brukowa stabilnie osiadła w piasku.</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Po zagęszczeniu, ponownie rozsyp piasek i wmień go w szczeliny.</span><br><br><br></p>
                    </li>
                </ul>
            </li>
        </ol>
        <p><strong><span style="font-size: 20px;">Krok 5: Finiszing</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
        <ol>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Ostatnie poprawki</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Sprawdź poziom nawierzchni za pomocą poziomicy i upewnij się, że kostki są r&oacute;wno ułożone.</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Jeśli zauważysz jakiekolwiek nier&oacute;wności, delikatnie popraw je młotkiem gumowym.</span><br><br></p>
                    </li>
                </ul>
            </li>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Czyszczenie</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Usuń nadmiar piasku z powierzchni kostki brukowej.</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Możesz użyć węża ogrodowego do delikatnego przepłukania nawierzchni, co pomoże w dalszym osiadaniu piasku w szczelinach.</span><br><br></p>
                    </li>
                </ul>
            </li>
        </ol>
        <p><strong><span style="font-size: 20px;">Krok 6: Konserwacja</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
        <ol>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Regularne czyszczenie</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Regularnie usuwaj zanieczyszczenia, liście i chwasty z powierzchni kostki brukowej.</span></p>
                    </li>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Możesz użyć myjki ciśnieniowej do okresowego czyszczenia kostki.</span><br><br></p>
                    </li>
                </ul>
            </li>
            <li style="list-style-type:decimal;font-size:12pt;">
                <p><strong><span style="font-size:12pt;">Dosypywanie piasku</span></strong><span style="font-size:12pt;">:</span><br><br></p>
                <ul>
                    <li style="list-style-type:disc;font-size:12pt;">
                        <p><span style="font-size:12pt;">Co jakiś czas sprawdzaj szczeliny między kostkami i w razie potrzeby dosypuj piasek, aby zapobiec ich przemieszczaniu się.</span><br><br><br></p>
                    </li>
                </ul>
            </li>
        </ol>
        <p style="text-align: justify;"><span style="font-size:12pt;">Układanie kostki brukowej to zadanie wymagające precyzji i staranności, ale z odpowiednim przygotowaniem i narzędziami można uzyskać trwały i estetyczny efekt. Powodzenia!</span></p>`,
  },
  {
    description: ` <p style="text-align: justify;"><span style="font-size:12pt;">Wyb&oacute;r kostki brukowej to kluczowy krok w tworzeniu trwałej i estetycznej nawierzchni. Oto szczeg&oacute;łowy poradnik, kt&oacute;ry pomoże Ci dokonać właściwego wyboru, biorąc pod uwagę r&oacute;żne aspekty funkcjonalne i estetyczne.</span><br><br><br></p>
    <p><strong><span style="font-size: 20px;">Krok 1: Określenie Zastosowania</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Rodzaj nawierzchni</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Podjazdy</span></strong><span style="font-size:12pt;">: Wybierz kostkę o grubości co najmniej 6 cm, kt&oacute;ra wytrzyma obciążenie samochod&oacute;w.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Chodniki i ścieżki ogrodowe</span></strong><span style="font-size:12pt;">: Kostka o grubości 4-6 cm będzie wystarczająca.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Tarasy i miejsca wypoczynkowe</span></strong><span style="font-size:12pt;">: Można użyć cieńszej kostki, np. 4 cm, ponieważ obciążenia będą mniejsze.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Przeznaczenie</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Dekoracyjne</span></strong><span style="font-size:12pt;">: Wybierz kostkę o ciekawych kształtach i kolorach, kt&oacute;ra będzie stanowić ozdobę.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Funkcjonalne</span></strong><span style="font-size:12pt;">: Skoncentruj się na trwałości i odporności na warunki atmosferyczne.</span><br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 2: Wyb&oacute;r Materiału</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Betonowa kostka brukowa</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Popularna i wszechstronna.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Dostępna w r&oacute;żnych kształtach, kolorach i wzorach.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Trwała i odporna na uszkodzenia mechaniczne.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Granitowa kostka brukowa</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Bardzo trwała i odporna na warunki atmosferyczne.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Wyjątkowy, naturalny wygląd.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Wyższa cena w por&oacute;wnaniu do betonowej.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Kostka z kamienia naturalnego (np. bazalt, piaskowiec)</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Naturalny wygląd i unikalne wzory.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">R&oacute;żnorodność kolor&oacute;w i tekstur.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Wyższa cena i trudniejszy montaż.</span></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Kostka ceramiczna</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Estetyczny wygląd, przypominający płytki.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Odpowiednia na tarasy i miejsca wypoczynkowe.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Mniej odporna na duże obciążenia mechaniczne.<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 3: Wyb&oacute;r Koloru i Kształtu</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Kolor</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Jasne kolory</span></strong><span style="font-size:12pt;">: Rozjaśniają przestrzeń i optycznie ją powiększają, ale mogą być bardziej podatne na zabrudzenia.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Ciemne kolory</span></strong><span style="font-size:12pt;">: Eleganckie i mniej podatne na zabrudzenia, ale mogą sprawiać wrażenie mniejszych przestrzeni.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Kolory naturalne</span></strong><span style="font-size:12pt;">: Odcienie szarości, beżu, brązu, kt&oacute;re harmonizują z otoczeniem.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Kształt i wz&oacute;r</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Prostokątne i kwadratowe</span></strong><span style="font-size:12pt;">: Klasyczne i łatwe w układaniu, pasujące do większości projekt&oacute;w.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Nieregularne kształty</span></strong><span style="font-size:12pt;">: Dodają charakteru i oryginalności, ale mogą być trudniejsze w montażu.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong><span style="font-size:12pt;">Mozaiki i wzory</span></strong><span style="font-size:12pt;">: Umożliwiają tworzenie dekoracyjnych motyw&oacute;w i unikalnych układ&oacute;w.</span><br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 4: Jakość i Wytrzymałość</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Grubość kostki</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Wybierz odpowiednią grubość kostki, zależnie od planowanego obciążenia nawierzchni.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Standardowe grubości: 4 cm (ścieżki), 6 cm (chodniki, tarasy), 8 cm i więcej (podjazdy).</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Odporność na warunki atmosferyczne</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Upewnij się, że kostka jest odporna na mr&oacute;z, wilgoć i promieniowanie UV.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Certyfikaty i atesty</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Sprawdź, czy kostka posiada odpowiednie certyfikaty jakości i zgodności z normami.</span><br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 5: Budżet</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Koszt materiału</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Por&oacute;wnaj ceny r&oacute;żnych rodzaj&oacute;w kostki brukowej.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Weź pod uwagę koszt dodatkowych materiał&oacute;w (np. podsypki, fug).</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Koszt montażu</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Jeśli planujesz zatrudnić fachowc&oacute;w, uwzględnij koszty robocizny.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Rozważ samodzielne układanie kostki, aby zaoszczędzić na kosztach.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Długoterminowe koszty utrzymania</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Wybierz materiał, kt&oacute;ry będzie łatwy w utrzymaniu i trwały, aby uniknąć częstych napraw i konserwacji.</span><br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 6: Estetyka i Harmonia z Otoczeniem</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Styl architektury</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Dobierz kostkę, kt&oacute;ra będzie harmonizować z stylem budynku i otoczenia.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Roślinność i krajobraz</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Zastan&oacute;w się, jak kostka będzie wsp&oacute;łgrać z istniejącą roślinnością i elementami krajobrazu.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Przykładowe realizacje</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Przeglądaj zdjęcia i inspiracje, aby zobaczyć, jak r&oacute;żne rodzaje kostki prezentują się w rzeczywistości.</span><br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Podsumowanie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <p style="text-align: justify;"><span style="font-size:12pt;">Wyb&oacute;r kostki brukowej wymaga rozważenia wielu czynnik&oacute;w, takich jak zastosowanie, materiał, kolor, kształt, jakość i budżet. Dobrze dobrana kostka brukowa nie tylko poprawi funkcjonalność i estetykę Twojej przestrzeni, ale także zwiększy jej wartość i trwałość. Pamiętaj, aby dokładnie planować i konsultować się z fachowcami, aby osiągnąć najlepszy efekt.</span></p>`,
  },
  {
    description: `<p style="text-align: justify;"><span style="font-size:12pt;">Wymiana lampy w pokoju to zadanie, kt&oacute;re można wykonać samodzielnie, jeśli posiada się podstawową wiedzę o elektryce i odpowiednie narzędzia. Oto krok po kroku, jak to zrobić:</span><br><br><br></p>
    <p><strong><span style="font-size: 20px;">Krok 1: Przygotowanie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Wyłącz zasilanie</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Przed rozpoczęciem prac upewnij się, że wyłączysz zasilanie w obwodzie, w kt&oacute;rym znajduje się lampa. Zr&oacute;b to poprzez wyłączenie odpowiedniego bezpiecznika w rozdzielnicy elektrycznej.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Sprawdź, czy lampa nie świeci, aby upewnić się, że prąd jest odłączony.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Zgromadzenie narzędzi i materiał&oacute;w</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Nowa lampa</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Śrubokręty (płaski i krzyżakowy)</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Pr&oacute;bnik napięcia</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Kleszcze lub szczypce</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Kostka elektryczna (jeśli nie jest dołączona do nowej lampy)</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Izolacyjna taśma<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 2: Demontaż Starej Lampy</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Sprawdzenie napięcia</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Użyj pr&oacute;bnika napięcia, aby upewnić się, że przewody są pozbawione prądu.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Odkręcenie starej lampy</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Usuń osłonę lub klosz starej lampy, aby uzyskać dostęp do mocowania.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Odkręć śruby mocujące lampę do sufitu.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Odłączenie przewod&oacute;w</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Delikatnie odłącz przewody elektryczne (fazowy, neutralny i ochronny) od kostki elektrycznej.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Zwr&oacute;ć uwagę na to, kt&oacute;re przewody były podłączone do kt&oacute;rych zacisk&oacute;w.<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 3: Montaż Nowej Lampy</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Przygotowanie nowej lampy</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Sprawdź instrukcję dołączoną do nowej lampy.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Jeśli lampa nie ma zamontowanej kostki elektrycznej, zamontuj ją zgodnie z instrukcją.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Podłączenie przewod&oacute;w</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Podłącz przewody z sufitu do kostki elektrycznej nowej lampy:</p>
                    <ul>
                        <li style="list-style-type:disc;font-size:12pt;">
                            <p>Przew&oacute;d fazowy (zazwyczaj brązowy lub czarny) do odpowiedniego zacisku.</p>
                        </li>
                        <li style="list-style-type:disc;font-size:12pt;">
                            <p>Przew&oacute;d neutralny (zazwyczaj niebieski) do odpowiedniego zacisku.</p>
                        </li>
                        <li style="list-style-type:disc;font-size:12pt;">
                            <p>Przew&oacute;d ochronny (zazwyczaj ż&oacute;łto-zielony) do odpowiedniego zacisku.</p>
                        </li>
                    </ul>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Upewnij się, że przewody są dobrze zamocowane i nie ma luźnych połączeń.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Mocowanie lampy do sufitu</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Przykręć nową lampę do sufitu za pomocą śrub mocujących.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Upewnij się, że lampa jest dobrze przymocowana i stabilna.<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 4: Sprawdzenie i Ukończenie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Ponowne włączenie zasilania</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Włącz bezpiecznik w rozdzielnicy elektrycznej, aby przywr&oacute;cić zasilanie.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Upewnij się, że lampa działa poprawnie i nie ma problem&oacute;w z jej działaniem.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Zakończenie montażu</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Jeśli wszystko działa poprawnie, zamocuj osłonę lub klosz nowej lampy.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Posprzątaj miejsce pracy i upewnij się, że wszystkie narzędzia są schowane.<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 5: Dodatkowe Wskaz&oacute;wki</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Bezpieczeństwo przede wszystkim</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Jeśli nie jesteś pewien swoich umiejętności elektrycznych, skonsultuj się z elektrykiem.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Nigdy nie pracuj z prądem, jeśli nie jesteś pewien, że zasilanie jest wyłączone.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Sprawdzenie przewod&oacute;w</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Przy okazji wymiany lampy warto sprawdzić stan przewod&oacute;w elektrycznych. Jeśli są uszkodzone lub stare, może być konieczna ich wymiana.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Zachowanie estetyki</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Staraj się, aby przewody były estetycznie ułożone i dobrze zabezpieczone.<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p style="text-align: justify;"><span style="font-size:12pt;">Wymiana lampy w pokoju to zadanie, kt&oacute;re można wykonać samodzielnie, zachowując odpowiednie środki ostrożności. Dzięki temu poradnikowi zyskasz nową lampę, kt&oacute;ra nie tylko poprawi oświetlenie, ale także doda uroku Twojemu wnętrzu.</span></p>`,
  },
  {
    description: `<p style="text-align: justify;"><span style="font-size:12pt;">Wymiana gniazda elektrycznego to zadanie, kt&oacute;re można wykonać samodzielnie, jeśli posiada się podstawową wiedzę na temat elektryki i odpowiednie narzędzia. Poniżej przedstawiam krok po kroku, jak to zrobić bezpiecznie i poprawnie.</span><br><br><br></p>
    <p><strong><span style="font-size: 20px;">Krok 1: Przygotowanie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Wyłączenie zasilania</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Przed rozpoczęciem prac, wyłącz zasilanie obwodu, na kt&oacute;rym będziesz pracować, poprzez wyłączenie odpowiedniego bezpiecznika w rozdzielnicy elektrycznej.</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Upewnij się, że gniazdo nie jest pod napięciem, używając pr&oacute;bnika napięcia.</span><br><br></p>
                </li>
            </ul>
        </li>
        <li style="list-style-type:decimal;font-size:12pt;">
            <p><strong><span style="font-size: 18px;">Zgromadzenie narzędzi i materiał&oacute;w</span></strong><span style="font-size: 18px;">:<br></span></p>
            <ul>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Nowe gniazdo elektryczne</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Śrubokręty (płaski i krzyżakowy)</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Pr&oacute;bnik napięcia</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Kleszcze lub szczypce</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">Izolacyjna taśma</span></p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><span style="font-size:12pt;">N&oacute;ż do usuwania izolacji</span><br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 2: Demontaż Starego Gniazda</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Sprawdzenie napięcia</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Użyj pr&oacute;bnika napięcia, aby upewnić się, że przewody są pozbawione prądu.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Odkręcenie osłony gniazda</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Usuń śruby mocujące osłonę gniazda i zdejmij osłonę.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Odkręcenie gniazda</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Odkręć śruby mocujące gniazdo do puszki elektrycznej i delikatnie wyciągnij gniazdo, aby uzyskać dostęp do przewod&oacute;w.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Odłączenie przewod&oacute;w</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Delikatnie odłącz przewody od zacisk&oacute;w gniazda, zwracając uwagę na to, kt&oacute;re przewody były podłączone do kt&oacute;rych zacisk&oacute;w.<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 3: Montaż Nowego Gniazda</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Przygotowanie przewod&oacute;w</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Jeśli końce przewod&oacute;w są uszkodzone lub mają za dużo izolacji, przytnij je i usuń odpowiednią ilość izolacji (zwykle około 1 cm).</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Podłączenie przewod&oacute;w</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Podłącz przewody do odpowiednich zacisk&oacute;w nowego gniazda:</p>
                    <ul>
                        <li style="list-style-type:disc;font-size:12pt;">
                            <p>Przew&oacute;d fazowy (zazwyczaj brązowy lub czarny) do zacisku oznaczonego literą &quot;L&quot;.</p>
                        </li>
                        <li style="list-style-type:disc;font-size:12pt;">
                            <p>Przew&oacute;d neutralny (zazwyczaj niebieski) do zacisku oznaczonego literą &quot;N&quot;.</p>
                        </li>
                        <li style="list-style-type:disc;font-size:12pt;">
                            <p>Przew&oacute;d ochronny (zazwyczaj ż&oacute;łto-zielony) do zacisku oznaczonego symbolem uziemienia (⏚).</p>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Zabezpieczenie przewod&oacute;w</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Upewnij się, że przewody są dobrze zamocowane w zaciskach i nie ma luźnych połączeń.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Jeżeli w puszce jest zbyt dużo przewod&oacute;w, staraj się je uporządkować, aby nie przeszkadzały przy montażu gniazda.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Mocowanie gniazda</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Delikatnie wciśnij gniazdo do puszki elektrycznej i przykręć je śrubami mocującymi.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Upewnij się, że gniazdo jest r&oacute;wno zamocowane i stabilne.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Założenie osłony gniazda</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Zamocuj osłonę gniazda za pomocą śrub mocujących.<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 4: Sprawdzenie i Ukończenie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Ponowne włączenie zasilania</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Włącz bezpiecznik w rozdzielnicy elektrycznej, aby przywr&oacute;cić zasilanie.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Upewnij się, że gniazdo działa poprawnie, podłączając do niego jakieś urządzenie elektryczne.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Ostateczne sprawdzenie</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Sprawdź, czy gniazdo jest stabilne i dobrze zamocowane.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Upewnij się, że osłona gniazda jest prawidłowo zamocowana i nie ma żadnych luźnych element&oacute;w.<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 5: Dodatkowe Wskaz&oacute;wki</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Bezpieczeństwo przede wszystkim</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Jeśli nie jesteś pewien swoich umiejętności elektrycznych, skonsultuj się z wykwalifikowanym elektrykiem.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Nigdy nie pracuj z prądem, jeśli nie jesteś pewien, że zasilanie jest wyłączone.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Sprawdzenie stanu przewod&oacute;w</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Przy okazji wymiany gniazda warto sprawdzić stan przewod&oacute;w elektrycznych. Jeśli są uszkodzone lub stare, może być konieczna ich wymiana.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Zachowanie estetyki</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Staraj się, aby przewody były estetycznie ułożone i dobrze zabezpieczone.<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p style="text-align: justify;"><span style="font-size:12pt;">Wymiana gniazda elektrycznego to zadanie, kt&oacute;re można wykonać samodzielnie, zachowując odpowiednie środki ostrożności. Dzięki temu poradnikowi zyskasz nowe, bezpieczne gniazdo, kt&oacute;re będzie dobrze funkcjonować i pasować do Twojego wnętrza.</span></p>`,
  },
  {
    description: `<p style="text-align: justify;"><span style="font-size:12pt;">Docieplenie domu to ważny krok w poprawie efektywności energetycznej budynku, co przekłada się na obniżenie koszt&oacute;w ogrzewania i zwiększenie komfortu cieplnego. Oto szczeg&oacute;łowy poradnik, jak samodzielnie docieplić dom.</span><br><br><br></p>
    <p><strong><span style="font-size: 20px;">Krok 1: Planowanie i Przygotowanie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Ocena potrzeb</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Zidentyfikuj obszary domu wymagające docieplenia (ściany zewnętrzne, dach, podłoga, okna i drzwi).</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Sprawdź stan istniejącej izolacji, jeśli jest taka zainstalowana.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Wyb&oacute;r materiał&oacute;w izolacyjnych</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong>Styropian</strong>: Popularny, tani, o dobrej izolacyjności.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong>Wełna mineralna</strong>: Dobrze izoluje, niepalna, dźwiękochłonna.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong>Płyty PIR</strong>: Najlepsze właściwości izolacyjne, ale droższe.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p><strong>Pianka poliuretanowa (PUR)</strong>: Szybka aplikacja, dobra izolacyjność.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Zakup narzędzi i materiał&oacute;w</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Materiał izolacyjny</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Klej do styropianu lub zaprawa klejowa</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Siatka zbrojąca</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Kołki montażowe</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Masa szpachlowa</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Tynk elewacyjny</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>N&oacute;ż do cięcia materiału izolacyjnego</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Paca zębata</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Wiertarka z mieszadłem</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Poziomica</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Szpachelka<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 2: Przygotowanie Powierzchni</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Czyszczenie ścian</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Usuń wszelkie zabrudzenia, luźne fragmenty farby i starej izolacji.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Umyj ściany wodą z detergentem i pozostaw do wyschnięcia.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Naprawa uszkodzeń</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Zaszpachluj pęknięcia i ubytki w ścianach.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Gruntowanie</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Nał&oacute;ż warstwę gruntu na ściany, aby poprawić przyczepność kleju.<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 3: Montaż Izolacji</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Przygotowanie kleju</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Przygotuj klej do styropianu lub zaprawę klejową zgodnie z instrukcją producenta.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Montaż płyt izolacyjnych</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Nał&oacute;ż klej na płyty izolacyjne, tworząc obw&oacute;d i kilka plack&oacute;w w środku.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Przył&oacute;ż płyty do ściany, zaczynając od dołu i postępując w g&oacute;rę.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Używaj poziomicy, aby upewnić się, że płyty są r&oacute;wno ułożone.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Stosuj naprzemienny układ płyt, aby zminimalizować mostki termiczne.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Mocowanie mechaniczne</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Po przyklejeniu płyt, dodatkowo przymocuj je kołkami montażowymi (zazwyczaj 6 kołk&oacute;w na m&sup2;).<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 4: Zbrojenie i Szpachlowanie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Nałożenie siatki zbrojącej</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Nał&oacute;ż warstwę kleju na przyklejone płyty izolacyjne.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Wciśnij siatkę zbrojącą w klej, dbając o zachowanie zakładki między paskami siatki (10 cm).</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Nał&oacute;ż kolejną warstwę kleju, aby całkowicie przykryć siatkę.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Szpachlowanie</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Po wyschnięciu kleju nał&oacute;ż warstwę szpachlową, aby uzyskać gładką powierzchnię.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Wyr&oacute;wnaj powierzchnię, używając pacy i poziomicy.<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 5: Wykończenie Elewacji</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Gruntowanie powierzchni</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Nał&oacute;ż grunt na powierzchnię ścian przed tynkowaniem.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Nakładanie tynku elewacyjnego</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Przygotuj tynk elewacyjny zgodnie z instrukcją producenta.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Nał&oacute;ż tynk r&oacute;wnomiernie na powierzchnię ścian, używając pacy.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Malowanie (opcjonalnie)</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Po wyschnięciu tynku możesz nałożyć farbę elewacyjną, aby nadać ścianom pożądany kolor i dodatkową ochronę.<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 6: Docieplenie Dachu i Podłogi (jeśli dotyczy)</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Docieplenie dachu</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Zainstaluj materiał izolacyjny między krokwiami dachowymi.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Nał&oacute;ż dodatkową warstwę izolacji na krokwie, aby zmniejszyć mostki termiczne.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Zamontuj folię paroizolacyjną i wykończ sufit płytami gipsowo-kartonowymi.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Docieplenie podłogi</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Zainstaluj warstwę izolacji na podłodze piwnicy lub na gruncie.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Zamontuj płyty styropianowe lub piankę poliuretanową.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Nał&oacute;ż warstwę betonu lub wykończ podłogę według potrzeb.<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Krok 7: Sprawdzenie Efekt&oacute;w i Konserwacja</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <ol>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Kontrola jakości</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Sprawdź, czy wszystkie powierzchnie są r&oacute;wno ocieplone i nie ma mostk&oacute;w termicznych.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Upewnij się, że wszystkie połączenia są szczelne.</p>
                </li>
            </ul>
        </li>
        <li style="list-style-type: decimal; font-size: 18px;">
            <p><strong>Regularna konserwacja</strong>:</p>
            <ul style="font-size: initial;">
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Regularnie sprawdzaj stan elewacji i dachu, aby upewnić się, że izolacja jest w dobrym stanie.</p>
                </li>
                <li style="list-style-type:disc;font-size:12pt;">
                    <p>Naprawiaj wszelkie uszkodzenia elewacji, aby zapobiec przenikaniu wilgoci.<br><br><br></p>
                </li>
            </ul>
        </li>
    </ol>
    <p><strong><span style="font-size: 20px;">Podsumowanie</span></strong><span style="font-size: 20px;"><br></span><br><br></p>
    <p style="text-align: justify;"><span style="font-size:12pt;">Docieplenie domu to inwestycja, kt&oacute;ra przynosi wiele korzyści, takich jak obniżenie koszt&oacute;w ogrzewania, poprawa komfortu cieplnego i zwiększenie wartości nieruchomości. Przestrzegając powyższych krok&oacute;w, można samodzielnie docieplić dom i cieszyć się jego zaletami przez wiele lat.</span></p>`,
  },
];
